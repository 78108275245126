async function generateSHA256Hash(input) {
	const encoder = new TextEncoder()
	const data = encoder.encode(input)
	const hashBuffer = await crypto.subtle.digest("SHA-256", data)
	const hashArray = Array.from(new Uint8Array(hashBuffer))
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("")
	return hashHex
}

const generateUUIDLikeHash = async (inputString: string): Promise<string> => {
	// Create a SHA-256 hash of the input string
	const hashHex = await generateSHA256Hash(inputString)
	// Take the first 128 bits (32 hex digits) of the hash
	const hashUUID = hashHex.substring(0, 32)

	// Format the 32 hex digits like a UUID: 8-4-4-4-12
	const formattedUUID = [
		hashUUID.substring(0, 8),
		hashUUID.substring(8, 12),
		hashUUID.substring(12, 16),
		hashUUID.substring(16, 20),
		hashUUID.substring(20),
	].join("-")

	return formattedUUID
}

export default generateUUIDLikeHash
