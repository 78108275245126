import React from "react"

import { CarbonCopy } from "./how-cards/CarbonCopy"
import { Chat } from "./how-cards/Chat"
import { Sell } from "./how-cards/Sell"
import { SectionHeader } from "./SectionHeader"

export const How = () => {
	return (
		<section id="process">
			<SectionHeader
				subheader="How it works"
				title="Revenge in 3 easy steps"
				description="Explore how AI-driven OnlyFans and Fansly chatter is revolutionizing agency interactions with fans."
			/>

			<div className="flex flex-col gap-2 p-2 md:flex-row md:gap-4 md:p-8 lg:grid lg:grid-cols-3">
				<CarbonCopy />
				<Chat />
				<Sell />
			</div>
		</section>
	)
}
