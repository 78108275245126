import { cubicBezier, motion } from "framer-motion"

export const Personal = () => {
	const variant1 = {
		initial: {
			y: 0,
			scale: 0.97,
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			y: -45,
			scale: 1,
			boxShadow:
				"rgba(39,127,245,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}
	const variant2 = {
		initial: {
			y: 30,
			opacity: 0,
			scale: 0.97,
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			y: 10,
			opacity: 1,
			scale: 1,
			boxShadow:
				"rgba(245,40,145,0.05) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}
	const variant3 = {
		initial: {
			x: 30,
			y: 20,
			opacity: 0,
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			x: 5,
			y: 5,
			opacity: 1,
			boxShadow:
				"rgba(39,245,76,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
			transition: {
				delay: 0.05,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}

	const containerVariants = {
		initial: {},
		whileHover: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	}

	return (
		<div className="relative h-full w-full transform-gpu bg-white">
			<motion.div
				variants={containerVariants}
				initial="initial"
				whileHover="whileHover"
				className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
			>
				<div className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-y-1 overflow-hidden rounded-t-xl bg-transparent">
					<div className="relative flex flex-col items-center justify-center gap-y-2 px-10 py-20">
						<motion.div
							variants={variant1}
							className="relative flex items-start gap-x-2 rounded-lg border border-stone-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-stone-800 dark:bg-stone-900"
						>
							<div className="h-6 w-6 rounded-full bg-blue-500">
								<img
									className="h-full w-full rounded-full object-cover"
									src="https://avatar.iran.liara.run/public/63"
									alt="joshua"
								/>
							</div>
							<div className="w-[calc(100%-3rem)]">
								<h3 className="text-base font-semibold">Joshua</h3>
								<p className="text-sm text-stone-500 dark:text-stone-400">
									i love what i saw in the last video
								</p>
							</div>
						</motion.div>
						<motion.div
							variants={variant2}
							className="absolute inset-10 -bottom-14 z-[3] m-auto flex h-fit items-start gap-x-2 rounded-lg border border-stone-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-stone-800 dark:bg-stone-900"
						>
							<div className="w-8">
								<div className="h-6 w-6 rounded-full bg-blue-500">
									<img
										className="h-full w-full rounded-full object-cover"
										src="https://avatar.iran.liara.run/public/60"
										alt="christina"
									/>
								</div>
							</div>
							<div className="w-[calc(100%-3rem)]">
								<h3 className="text-base font-semibold">Sara</h3>
								<p className="text-sm text-stone-500 dark:text-stone-400">
									thanks, i&apos;d love to show you more Joshua
								</p>
							</div>
							<motion.p
								variants={variant3}
								className="highlighted absolute -bottom-2 right-2 w-fit rounded-full border bg-cyan-500 px-2 py-0.5 text-xs text-white"
							>
								sent
							</motion.p>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</div>
	)
}
