import React, { useState } from "react"
import { Loader2, Trash2 } from "lucide-react"
import CurrencyInput from "react-currency-input-field"
import { Badge } from "src/components/ui/Badge"
import { Button } from "src/components/ui/Button"
import { Input } from "src/components/ui/Input"
import { MultiSelector } from "src/components/ui/MultiSelector"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/Select"
import { TableCell, TableRow } from "src/components/ui/Table"
import { Textarea } from "src/components/ui/Textarea"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "src/components/ui/Tooltip"
import { cn } from "src/lib/utils"

import action_types from "../action_types"

import LoadingIndicator, { LoadingState } from "./LoadingIndicator"
import { ContentItem, ContentItemErrors } from "./useUpdateContent"

interface ContentRowComponentProps {
	content: ContentItem
	updateContent: (key: string, value: any) => void
	deleteContent: () => void
	is_updating: boolean
	is_deleting: boolean
	update_error: boolean
	errors: ContentItemErrors
	update_info: string
	loading_state: LoadingState
	isEditing: boolean
}

const ContentRowComponent: React.FC<ContentRowComponentProps> = ({
	content,
	updateContent,
	deleteContent,
	is_updating,
	is_deleting,
	update_error,
	errors,
	update_info,
	loading_state,
	isEditing,
}) => {
	const [isDeleteConfirm, setIsDeleteConfirm] = useState(false)

	if (is_deleting) {
		return (
			<TableRow>
				<TableCell colSpan={7} className="text-center">
					<Loader2 className="mx-auto w-8 h-8 animate-spin" />
					<p>Deleting content...</p>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<TableRow key={content.id}>
			{isEditing && (
				<TableCell>
					<LoadingIndicator state={loading_state} tooltip={update_info} />
				</TableCell>
			)}
			<TableCell className="hidden sm:table-cell">
				<img
					alt="Product image"
					className="object-cover rounded-md aspect-square bg-stone-pale"
					height="64"
					src={content.read_url}
					width="64"
				/>
			</TableCell>
			<TableCell className="bold">
				{isEditing ? (
					<Input
						className="w-full"
						value={content.title}
						onChange={e => updateContent("title", e.target.value)}
					/>
				) : (
					<TableCell>{content.title}</TableCell>
				)}
			</TableCell>
			<TableCell className="flex-wrap detail">
				{isEditing ? (
					<Textarea
						value={content.description}
						onChange={e => updateContent("description", e.target.value)}
					/>
				) : (
					<Tooltip>
						<TooltipTrigger asChild>
							<span className="line-clamp-1">{content.description}</span>
						</TooltipTrigger>
						<TooltipContent className="max-w-[320px]">
							<p className="para">{content.description}</p>
						</TooltipContent>
					</Tooltip>
				)}
			</TableCell>
			<TableCell className="w-24 bold">
				{isEditing ? (
					<CurrencyInput
						id="price"
						name="price"
						placeholder="Price"
						prefix="$"
						className={cn(
							"flex px-4 py-4 w-full h-12 font-sans rounded-2xl border file: border-input bg-background ring-offset-background file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
							"w-24"
						)}
						defaultValue={content.price}
						decimalsLimit={2}
						onValueChange={value => {
							const numericValue = value ? parseFloat(value) : 0
							updateContent("price", numericValue)
						}}
					/>
				) : (
					<TableCell>{`$${content.price.toFixed(2)}`}</TableCell>
				)}
			</TableCell>
			<TableCell>
				{isEditing ? (
					<Select
						value={content.explicit_level}
						onValueChange={value => updateContent("explicit_level", value)}
					>
						<SelectTrigger>
							<SelectValue placeholder="Select level" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="SFW">1. SFW</SelectItem>
							<SelectItem value="NSFW">2. NSFW</SelectItem>
							<SelectItem value="Softcore">3. Softcore</SelectItem>
							<SelectItem value="Hardcore">4. Hardcore</SelectItem>
						</SelectContent>
					</Select>
				) : (
					<TableCell>{content.explicit_level}</TableCell>
				)}
			</TableCell>
			<TableCell className="max-w-[240px]">
				{isEditing ? (
					<MultiSelector
						values={content.tags.map(tag => ({
							value: tag,
							label: tag,
						}))}
						onValuesChange={values =>
							updateContent(
								"tags",
								values.map(v => v.value)
							)
						}
						options={action_types}
					/>
				) : (
					<TableCell className="flex flex-wrap gap-1">
						{content.tags.slice(0, 3).map(tag => (
							<Badge variant="secondary" key={tag}>
								{tag}
							</Badge>
						))}
						{content.tags.length > 3 && (
							<Tooltip>
								<TooltipTrigger asChild>
									<span className="flex items-center cursor-pointer">
										<Badge variant="outline">Show all</Badge>
									</span>
								</TooltipTrigger>
								<TooltipContent className="max-w-[320px]">
									<p className="flex flex-wrap gap-1">
										{content.tags.map(tag => (
											<Badge variant="secondary" key={tag}>
												{tag}
											</Badge>
										))}
									</p>
								</TooltipContent>
							</Tooltip>
						)}
					</TableCell>
				)}
			</TableCell>
			{isEditing && (
				<TableCell>
					<Tooltip open={isDeleteConfirm}>
						<TooltipTrigger asChild>
							<Button
								variant={isDeleteConfirm ? "destructive" : "ghost"}
								size="icon"
								onClick={() => {
									if (isDeleteConfirm) {
										deleteContent()
									} else {
										setIsDeleteConfirm(true)
									}
								}}
								onMouseLeave={() => setIsDeleteConfirm(false)}
							>
								<Trash2 className="w-4 h-4" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="max-w-[320px] bg-red-root">
							<p>Are you sure?</p>
						</TooltipContent>
					</Tooltip>
				</TableCell>
			)}
		</TableRow>
	)
}

export default ContentRowComponent
