import React from "react"
import { TrendingUp } from "lucide-react"
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface BestDaysProps {
	data: Array<{ name: string; value: number }>
}

const chartConfig: ChartConfig = {
	value: {
		label: "Revenue",
		color: "#99C74E",
	},
}

export const BestDays: React.FC<BestDaysProps> = ({ data }) => (
	<Card className="col-span-1">
		<CardHeader>
			<CardTitle>Best Days</CardTitle>
			<CardDescription>Your most profitable days</CardDescription>
		</CardHeader>
		<CardContent>
			<ChartContainer config={chartConfig}>
				<ResponsiveContainer width="100%" height={250}>
					<BarChart className="w-full aspect-[3/2]" data={data}>
						<XAxis dataKey="name" />
						<ChartTooltip content={<ChartTooltipContent />} />
						<Bar
							dataKey="value"
							fill="var(--color-value)"
							radius={[4, 4, 0, 0]}
						/>
					</BarChart>
				</ResponsiveContainer>
			</ChartContainer>
		</CardContent>
		<CardFooter className="flex-col gap-2 items-start text-sm">
			<div className="flex gap-2 font-medium leading-none">
				Trending up by 1.2% from last week <TrendingUp className="w-4 h-4" />
			</div>
			<div className="leading-none text-muted-foreground">
				Showing last 7 days
			</div>
		</CardFooter>
	</Card>
)
