import { Building2, CircleUser } from "lucide-react"
import { useNavigate } from "react-router-dom"

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../components/ui/Card"

export const AccountScreen = () => {
	const navigate = useNavigate()

	const handleSelection = (type: "content-creator" | "agency") => {
		navigate(`/onboarding/persona?type=${type}`)
	}

	const accountTypes = [
		{
			type: "content-creator",
			icon: CircleUser,
			title: "Content Creator",
			description: "you are a solo creator",
		},
		{
			type: "agency",
			icon: Building2,
			title: "Agency",
			description: "you are an agency with a team",
		},
	]

	return (
		<div className="flex flex-col flex-1 gap-4 md:flex-row">
			<Card className="w-full">
				<CardHeader>
					<CardTitle>Choose account type</CardTitle>
					<CardDescription>
						The type of account helps us better tailor your experience to your
						needs
					</CardDescription>
				</CardHeader>
				<CardContent className="flex flex-row gap-4">
					{accountTypes.map(({ type, icon: Icon, title, description }) => (
						<Card
							key={type}
							className="relative w-full cursor-pointer bg-stone-pale hover:bg-green-root"
							onClick={() =>
								handleSelection(type as "content-creator" | "agency")
							}
						>
							<CardContent className="flex flex-col justify-center items-center p-6 h-full">
								<Icon className="mb-4 w-8 h-8 text-stone-root" />
								<p className="text-center subheader text-stone-root">{title}</p>
								<p className="detail text-stone-root">{description}</p>
							</CardContent>
						</Card>
					))}
				</CardContent>
			</Card>
		</div>
	)
}
