import { useEffect, useId, useRef } from "react"
import { motion, useAnimation, useInView } from "framer-motion"
import {
	BadgeDollarSign,
	CircleDollarSign,
	DollarSign,
	Euro,
	JapaneseYen,
	PoundSterling,
} from "lucide-react"
import { Marquee } from "src/components/effects/Marquee"
import { cn } from "src/lib/utils"
const tiles = [
	{
		icon: <CircleDollarSign className="size-full text-blue-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-blue-root via-blue-mid to-blue-dark opacity-20 blur-[20px] filter"></div>
		),
	},
	{
		icon: <Euro className="size-full text-orange-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-orange-root via-orange-mid to-orange-dark opacity-20 blur-[20px] filter"></div>
		),
	},
	{
		icon: <PoundSterling className="size-full text-red-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-red-root via-red-mid to-red-dark opacity-20 blur-[20px] filter"></div>
		),
	},
	{
		icon: <DollarSign className="size-full text-green-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-green-root via-green-mid to-green-dark opacity-20 blur-[20px] filter"></div>
		),
	},
	{
		icon: <BadgeDollarSign className="size-full text-yellow-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-yellow-root via-yellow-mid to-yellow-dark opacity-20 blur-[20px] filter"></div>
		),
	},
	{
		icon: <JapaneseYen className="size-full text-purple-root" />,
		bg: (
			<div className="pointer-events-none absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-purple-root via-purple-mid to-purple-dark opacity-20 blur-[20px] filter"></div>
		),
	},
]

const shuffleArray = (array: any[]) => {
	let currentIndex = array.length,
		randomIndex
	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--
		// And swap it with the current element.
		;[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		]
	}
	return array
}

const randomTiles1 = shuffleArray([...tiles])
const randomTiles2 = shuffleArray([...tiles])
const randomTiles3 = shuffleArray([...tiles])
const randomTiles4 = shuffleArray([...tiles])

const Card = (card: { icon: JSX.Element; bg: JSX.Element }) => {
	const id = useId()
	const controls = useAnimation()
	const ref = useRef(null)
	const inView = useInView(ref, { once: true })

	useEffect(() => {
		if (inView) {
			controls.start({
				opacity: 1,
				transition: {
					delay: Math.random() * 2,
					ease: "easeOut",
					duration: 1,
				},
			})
		}
	}, [controls, inView])

	return (
		<motion.div
			key={id}
			ref={ref}
			initial={{ opacity: 0 }}
			animate={controls}
			className={cn(
				"relative size-20 cursor-pointer overflow-hidden rounded-2xl p-4",
				// light styles
				"bg-white"
			)}
		>
			{card.icon}
			{card.bg}
		</motion.div>
	)
}

export const Sell = () => {
	const containerVariants = {
		initial: {},
		whileHover: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	}

	return (
		<div className="relative h-full w-full transform-gpu rounded-2xl bg-white md:max-h-[500px]">
			<motion.div
				variants={containerVariants}
				initial="initial"
				whileHover="whileHover"
				className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
			>
				<div className="group relative flex h-[300px] w-full cursor-pointer flex-col items-center justify-center gap-y-1 overflow-hidden rounded-t-xl p-4">
					<div className="relative flex flex-col items-center justify-center gap-y-2 px-10">
						<Marquee
							reverse
							className="-delay-[200ms] [--duration:20s]"
							repeat={4}
						>
							{randomTiles1.map((review, idx) => (
								<Card key={idx} {...review} />
							))}
						</Marquee>
						<Marquee reverse className="[--duration:30s]" repeat={4}>
							{randomTiles2.map((review, idx) => (
								<Card key={idx} {...review} />
							))}
						</Marquee>
						<Marquee
							reverse
							className="-delay-[200ms] [--duration:20s]"
							repeat={4}
						>
							{randomTiles3.map((review, idx) => (
								<Card key={idx} {...review} />
							))}
						</Marquee>
						<Marquee reverse className="[--duration:30s]" repeat={4}>
							{randomTiles4.map((review, idx) => (
								<Card key={idx} {...review} />
							))}
						</Marquee>
						<div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
						<div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
					</div>
				</div>
				<div className="flex w-full flex-col items-start p-4">
					<h3 className="">3. Sell More PPV</h3>
					<p className="text-base font-normal text-stone-light">
						Using best in call sales scripts and selling methods
					</p>
				</div>
			</motion.div>
		</div>
	)
}
