import { useEffect, useState } from "react"
import trpc_client from "src/lib/trpc"

import { ContentSetJSON } from "../../../../../api/src/models/content/content_set.model"

export const useScenarios = (persona_id: string) => {
	const [scenarios, setScenarios] = useState<ContentSetJSON[]>([])
	const [is_loading, setIsLoading] = useState(true)
	const [error, setError] = useState<Error | null>(null)

	const fetchScenarios = async () => {
		setIsLoading(true)
		try {
			const content_sets = await trpc_client.content.set.getAll.query({
				persona_id,
			})
			setScenarios(content_sets)
			setError(null)
		} catch (err) {
			setError(
				err instanceof Error
					? err
					: new Error("An error occurred while fetching scenarios")
			)
		} finally {
			setIsLoading(false)
		}
	}

	const updateScenario = async (
		content_set_id: string,
		update: Partial<ContentSetJSON>
	) => {
		try {
			await trpc_client.content.set.update.mutate({
				content_set_id,
				update,
			})
			await fetchScenarios() // Refetch scenarios after update
		} catch (err) {
			setError(
				err instanceof Error ? err : new Error("Failed to update scenario")
			)
			throw err
		}
	}

	const toggleScenarioArchived = async (scenario_id: string) => {
		try {
			await trpc_client.content.set.toggleArchived.mutate({
				content_set_id: scenario_id,
			})
			// Refresh scenarios after toggling
			await fetchScenarios()
		} catch (error) {
			console.error("Failed to toggle scenario archived status:", error)
			throw error
		}
	}

	useEffect(() => {
		fetchScenarios()
	}, [persona_id])

	return {
		scenarios,
		is_loading,
		error,
		refetchScenarios: fetchScenarios,
		updateScenario,
		toggleScenarioArchived,
	}
}
