import React, { useState } from "react"
import { FileText, Info, MessageSquare } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "src/components/ui/Dialog"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import { StepItem, Steps } from "src/components/ui/Steps"
import { Textarea } from "src/components/ui/Textarea"
import { TooltipCard, TooltipContent } from "src/components/ui/TooltipCard"
import trpc_client from "src/lib/trpc"
import generateUUIDLikeHash from "src/lib/uuid_like_hash"

import ContentTable from "../ContentTable"

const steps: StepItem[] = [
	{ step: 1, label: "Details" },
	{ step: 2, label: "Add Content" },
]

const ScenarioForm = ({
	isOpen,
	onClose,
	persona_id,
	isModal = true,
}: {
	isOpen: boolean
	onClose: () => void
	persona_id: string
	isModal?: boolean
}) => {
	const navigate = useNavigate()
	const [currentStep, setCurrentStep] = useState(0)

	const [form_data, setFormData] = useState({
		title: "",
		description: "",
		conversation_starter: "",
	})

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}))
	}

	const [scenario_submitting, setScenarioSubmitting] = useState(false)

	const [content_set_id, setContentSetId] = useState("")

	const handleScenarioCreation = async () => {
		try {
			setScenarioSubmitting(true)
			const content_set_id = await generateUUIDLikeHash(
				`${persona_id}:${form_data.title}`
			)
			await trpc_client.content.set.create.mutate({
				id: content_set_id,
				persona_id,
				title: form_data.title,
				description: form_data.description,
				conversation_starter: form_data.conversation_starter,
			})
			setScenarioSubmitting(false)
			setContentSetId(content_set_id)
			setCurrentStep(1)
		} catch (error) {
			console.error(error)
			toast.error("Failed to create scenario")
		} finally {
			setScenarioSubmitting(false)
		}
	}

	const [activeField, setActiveField] = useState<string | null>(null)

	const tooltipContent: Record<string, TooltipContent> = {
		title: {
			icon: FileText,
			text: (
				<span>
					Enter a title for your scenario. This will help you identify it later.
				</span>
			),
		},
		description: {
			icon: Info,
			text: (
				<span>
					Provide a brief description of the scenario. This can include context
					or any specific details.
				</span>
			),
		},
		conversation_starter: {
			icon: MessageSquare,
			text: (
				<span>
					Enter the initial message or prompt that will start the conversation
					in this scenario.
				</span>
			),
		},
	}

	const resetForm = () => {
		setCurrentStep(0)
		setFormData({
			title: "",
			description: "",
			conversation_starter: "",
		})
		setContentSetId("")
	}

	const handleNextStep = async () => {
		if (currentStep === 0) {
			await handleScenarioCreation()
			if (!content_set_id) return // Stop if scenario creation failed
		}

		if (isModal) {
			if (currentStep === steps.length - 1) {
				onClose()
				resetForm()
			} else {
				setCurrentStep(currentStep + 1)
			}
		} else if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1)
		} else {
			navigate(`/onboarding/review?persona_id=${persona_id}`)
			resetForm()
		}
	}

	const getButtonText = () => {
		if (isModal) {
			if (currentStep === 0) return "Next"
			return "Save and Close"
		}
		if (currentStep === 0) return "Create Scenario"
		if (currentStep === steps.length - 1) return "Save and Continue"
		return "Next"
	}

	const formContent = (
		<>
			<Steps steps={steps} currentStep={currentStep} />
			{currentStep === 0 && (
				<div className="grid grid-cols-3">
					<Card className="flex flex-col col-span-2 gap-4">
						<CardHeader>
							<CardTitle>Details</CardTitle>
						</CardHeader>
						<CardContent className="grid gap-4">
							<div className="flex flex-col gap-4">
								<Label htmlFor="title">Title</Label>
								<Input
									id="title"
									name="title"
									value={form_data.title}
									onChange={handleChange}
									onFocus={() => setActiveField("title")}
								/>
							</div>
							<div className="flex flex-col gap-4">
								<Label htmlFor="description">Description</Label>
								<Textarea
									id="description"
									name="description"
									value={form_data.description}
									onChange={handleChange}
									onFocus={() => setActiveField("description")}
								/>
							</div>
							<div className="flex flex-col gap-4">
								<Label htmlFor="conversation_starter">
									Conversation Starts
								</Label>
								<Textarea
									id="conversation_starter"
									name="conversation_starter"
									value={form_data.conversation_starter}
									onChange={handleChange}
									onFocus={() => setActiveField("conversation_starter")}
								/>
							</div>
						</CardContent>
					</Card>
					<div className="col-span-1">
						<TooltipCard
							activeField={activeField}
							tooltipContent={tooltipContent}
						/>
					</div>
				</div>
			)}
			{currentStep === 1 && (
				<ContentTable
					content_set_id={content_set_id}
					persona_id={persona_id}
					isEditing={true}
				/>
			)}
			<div className="flex gap-2 justify-end mt-4">
				{isModal && (
					<Button variant="outline" onClick={onClose}>
						Cancel
					</Button>
				)}
				<Button
					onClick={handleNextStep}
					disabled={currentStep === 0 && !form_data.title}
				>
					{getButtonText()}
				</Button>
			</div>
		</>
	)

	if (!isModal) {
		return formContent
	}

	return (
		<Dialog
			open={isOpen}
			onOpenChange={() => {
				onClose()
				resetForm()
			}}
		>
			<DialogContent className="max-w-[80%] max-h-[80%] overflow-y-scroll bg-stone-pale">
				<DialogHeader>
					<DialogTitle>Create New Scenario</DialogTitle>
				</DialogHeader>
				{formContent}
			</DialogContent>
		</Dialog>
	)
}

export default ScenarioForm
