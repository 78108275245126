"use client"

import * as React from "react"
import { TrendingUp } from "lucide-react"
import { Label, Pie, PieChart } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface PopularContentProps {
	data: Array<{ category: string; revenue: number; fill: string }>
}

const chartConfig: ChartConfig = {
	revenue: {
		label: "Revenue",
	},
}

export const PopularContent: React.FC<PopularContentProps> = ({ data }) => {
	const totalRevenue = React.useMemo(() => {
		return data.reduce((acc, curr) => acc + curr.revenue, 0)
	}, [data])

	return (
		<Card className="flex flex-col h-full">
			<CardHeader className="pb-0">
				<CardTitle>Most Popular Content</CardTitle>
				<CardDescription>Revenue by category</CardDescription>
			</CardHeader>
			<CardContent className="flex flex-1 justify-center items-center pb-0">
				<ChartContainer config={chartConfig} className="w-full h-full">
					<PieChart>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
						/>
						<Pie
							data={data}
							dataKey="revenue"
							nameKey="category"
							innerRadius="60%"
							outerRadius="80%"
							strokeWidth={5}
						>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy}
													className="text-3xl font-bold fill-foreground"
												>
													${totalRevenue.toLocaleString()}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) + 24}
													className="fill-muted-foreground"
												>
													Total Revenue
												</tspan>
											</text>
										)
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 items-start text-sm">
				<div className="flex gap-2 font-medium leading-none">
					Trending up by 5.2% this month <TrendingUp className="w-4 h-4" />
				</div>
				<div className="leading-none text-muted-foreground">
					Showing top 5 content and their revenue
				</div>
			</CardFooter>
		</Card>
	)
}
