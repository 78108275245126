import { Link } from "react-router-dom"
import { Avatar, AvatarFallback } from "src/components/ui/Avatar"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"

import { PublicSafePersona } from "../../../../../api/src/models/persona/persona.schema"

export const PersonaCard = ({ persona }: { persona: PublicSafePersona }) => {
	return (
		<Link
			key={persona.id}
			className="flex flex-col h-full"
			to={`/persona/${persona.id}`}
		>
			<Card className="flex-1">
				<CardHeader>
					<div className="flex gap-2 items-center">
						<Avatar className="size-12">
							<div className="overflow-hidden relative rounded-full aspect-square size-12">
								{persona.avatar_image ? (
									<img
										src={persona.avatar_image}
										alt={persona.name ?? "Avatar"}
										className="object-cover w-full h-full"
									/>
								) : (
									<AvatarFallback>
										{persona.name && persona.name.charAt(0).toUpperCase()}
									</AvatarFallback>
								)}
							</div>
						</Avatar>
						<CardTitle>{persona.name}</CardTitle>
					</div>
				</CardHeader>
				<CardContent>
					<CardDescription>{persona.about}</CardDescription>
				</CardContent>
				<CardFooter>
					<p className="underline detail text-stone-light">See more details</p>
				</CardFooter>
			</Card>
		</Link>
	)
}
