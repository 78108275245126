import PersonaComponent from "./Persona.component"
import { usePersona } from "./usePersona"

const Persona = ({ persona_id }: { persona_id: string }) => {
	const { persona, isLoading, updatePersona } = usePersona(persona_id)

	return (
		<PersonaComponent
			persona={persona}
			isLoading={isLoading}
			updatePersona={updatePersona}
		/>
	)
}

export default Persona
