import "./global.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Toaster } from "sonner"

import App from "./App"
import { Footer } from "./components/blocks/Footer"
import { Header } from "./components/blocks/Header"
import { TooltipProvider } from "./components/ui/Tooltip"
import { AuthProvider } from "./providers/AuthProvider"
import { PHProvider } from "./providers/PostHog"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<PHProvider>
					<TooltipProvider>
						<Header />
						<App />
						<Footer />
						<Toaster />
					</TooltipProvider>
				</PHProvider>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>
)
