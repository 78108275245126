import { S3Response } from "react-s3-uploader"
import { Alert, AlertDescription, AlertTitle } from "src/components/ui/Alert"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	Table,
	TableBody,
	TableHead,
	TableHeader,
} from "src/components/ui/Table"
import { TableRow } from "src/components/ui/Table"

import BulkUploader, { UploaderConfig } from "./BulkUploader"
import ContentRow from "./ContentRow"
import { ContentItem } from "./ContentRow/useUpdateContent"

export interface ContentAggregateMetadata {
	total_files: number
	total_size: number
}

interface ContentTableProps {
	persona_id: string
	content_set_id: string
	content: ContentItem[]
	onDelete: (content_id: string) => void
	onFileUploaded: (file: S3Response) => void
	onError: (error: Error) => void
	upload_config: UploaderConfig
	isEditing: boolean
	is_loading: boolean
	toggleEditing?: () => void
}

const ContentTableComponent = ({
	persona_id,
	content_set_id,
	content,
	onDelete,
	onFileUploaded,
	onError,
	upload_config,
	isEditing,
	toggleEditing,
	is_loading,
}: ContentTableProps) => {
	return (
		<Card className="w-full">
			<CardHeader className="flex flex-row justify-between items-center">
				<CardTitle>Content</CardTitle>
				{toggleEditing && (
					<Button
						variant={isEditing ? "outline" : "default"}
						onClick={toggleEditing}
					>
						{isEditing ? "Done" : "Add or Edit Content"}
					</Button>
				)}
				{is_loading && <div>Loading...</div>}
			</CardHeader>
			<CardContent className="flex overflow-visible flex-col gap-2">
				{isEditing && (
					<>
						<Alert variant="info">
							<AlertTitle>
								All the changes you make will be auto saved
							</AlertTitle>
							<AlertDescription className="flex flex-col gap-2 p-2 mt-2 bg-white rounded-2xl">
								<span>Status Key:</span>
								<ul className="pl-2 list-disc">
									<li className="flex gap-2 items-center">
										<div className="w-3 h-3 rounded-full bg-green-mid" />=
										Content saved successfully.
									</li>
									<li className="flex gap-2 items-center">
										<div className="w-3 h-3 rounded-full bg-yellow-root" />=
										Content is currently being saved.
									</li>
									<li className="flex gap-2 items-center">
										<div className="w-3 h-3 rounded-full bg-red-root" />= There
										was an error saving the content.
									</li>
								</ul>
							</AlertDescription>
						</Alert>
						<BulkUploader
							persona_id={persona_id}
							onSuccess={onFileUploaded}
							onError={onError}
							config={upload_config}
						/>
					</>
				)}
				{content.length > 0 && (
					<div className="grid overflow-x-auto gap-2">
						<Table className="rounded-2xl border-stone-pale bg-stone-white">
							<TableHeader>
								<TableRow>
									{isEditing && <TableHead>Status</TableHead>}
									<TableHead className="hidden w-[100px] sm:table-cell">
										Thumbnail
									</TableHead>
									<TableHead>Title</TableHead>
									<TableHead>Description</TableHead>
									<TableHead>Price</TableHead>
									<TableHead>Level</TableHead>
									<TableHead>Tags</TableHead>
									{isEditing && <TableHead>Actions</TableHead>}
								</TableRow>
							</TableHeader>
							<TableBody>
								<>
									{content.map(item => (
										<ContentRow
											key={item.id}
											item={item}
											onDelete={onDelete}
											isEditing={isEditing}
											content_set_id={content_set_id}
										/>
									))}
								</>
							</TableBody>
						</Table>
					</div>
				)}
			</CardContent>
		</Card>
	)
}

export default ContentTableComponent
