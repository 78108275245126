import { useNavigate, useSearchParams } from "react-router-dom"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import CreatePersona from "src/components/ui/CreatePersona"

export const PersonaScreen = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	// if the type is agency then  give the option to invite a content creator
	const invite_content_creator = searchParams.get("type") == "agency"

	const onCreatePersona = (persona_id: string) => {
		navigate(`/onboarding/scenario?persona_id=${persona_id}`)
	}

	return (
		<div className="flex flex-col flex-1 gap-4 md:flex-row">
			<Card className="w-full">
				<CardHeader>
					<CardTitle>Create Your Persona</CardTitle>
					<CardDescription>
						Fill in the details to set up your persona
					</CardDescription>
				</CardHeader>
				<CardContent>
					<CreatePersona
						invite_content_creator={invite_content_creator}
						onSuccess={onCreatePersona}
					/>
				</CardContent>
			</Card>
		</div>
	)
}
