import React, { forwardRef, useRef } from "react"
import { User2 } from "lucide-react"
import { AnimatedBeam } from "src/components/effects/AnimatedBeam"
import { cn } from "src/lib/utils"
const Circle = forwardRef<
	HTMLDivElement,
	{ className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				"z-10 flex h-12 w-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
				className
			)}
		>
			{children}
		</div>
	)
})
Circle.displayName = "Circle"
export function Streams() {
	const containerRef = useRef<HTMLDivElement>(null)
	const div1Ref = useRef<HTMLDivElement>(null)
	const div2Ref = useRef<HTMLDivElement>(null)
	const div3Ref = useRef<HTMLDivElement>(null)
	const div4Ref = useRef<HTMLDivElement>(null)
	const div5Ref = useRef<HTMLDivElement>(null)
	const div6Ref = useRef<HTMLDivElement>(null)
	const div7Ref = useRef<HTMLDivElement>(null)

	return (
		<div
			className="relative flex max-h-[200px] w-full items-center justify-center overflow-hidden md:max-h-full"
			ref={containerRef}
		>
			<div className="flex h-full w-full flex-col items-stretch justify-between gap-10 p-16">
				<div className="flex flex-row items-center justify-between">
					<div
						ref={div1Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img src="/icons/fansly.png" alt="fansly" width={32} height={32} />
					</div>
					<div
						ref={div5Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img
							src="/icons/insta.png"
							alt="instagram"
							width={32}
							height={32}
						/>
					</div>
				</div>
				<div className="flex flex-row items-center justify-between">
					<div
						ref={div2Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img
							src="/icons/onlyfans.png"
							alt="onlyfans"
							width={32}
							height={32}
						/>
					</div>
					<Circle ref={div4Ref} className="h-16 w-16">
						<User2 className="h-6 w-6" />
					</Circle>
					<div
						ref={div6Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img
							src="/icons/telegram.png"
							alt="telegram"
							width={32}
							height={32}
						/>
					</div>
				</div>
				<div className="flex flex-row items-center justify-between">
					<div
						ref={div3Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img src="/icons/twitch.png" alt="twitch" width={32} height={32} />
					</div>
					<div
						ref={div7Ref}
						className="z-10 rounded-full border-2 border-stone-pale bg-white p-2"
					>
						<img
							src="/icons/twitter.png"
							alt="twitter"
							width={32}
							height={32}
						/>
					</div>
				</div>
			</div>

			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div1Ref}
				toRef={div4Ref}
				curvature={-75}
				endYOffset={-10}
			/>
			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div2Ref}
				toRef={div4Ref}
			/>
			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div3Ref}
				toRef={div4Ref}
				curvature={75}
				endYOffset={10}
			/>
			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div5Ref}
				toRef={div4Ref}
				curvature={-75}
				reverse
				endYOffset={-10}
			/>
			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div6Ref}
				toRef={div4Ref}
				reverse
			/>
			<AnimatedBeam
				containerRef={containerRef}
				fromRef={div7Ref}
				toRef={div4Ref}
				curvature={75}
				reverse
				endYOffset={10}
			/>
		</div>
	)
}
