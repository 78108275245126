import React from "react"
import { LucideIcon } from "lucide-react"

import { Card, CardContent, CardHeader, CardTitle } from "./Card"

export type TooltipContent = {
	icon: LucideIcon
	text: React.ReactNode
}

interface TooltipCardProps {
	activeField: string | null
	tooltipContent: Record<string, TooltipContent>
	defaultTitle?: string
}

export const TooltipCard: React.FC<TooltipCardProps> = ({
	activeField,
	tooltipContent,
	defaultTitle = "Field Information",
}) => {
	const content = activeField && tooltipContent[activeField]

	return (
		<Card className="bg-stone-pale text-stone-mid">
			<CardHeader>
				{content && <content.icon className="w-6 h-6" />}
				<CardTitle>
					{activeField
						? activeField
								.replace(/_/g, " ")
								.replace(/\b\w/g, char => char.toUpperCase())
						: defaultTitle}
				</CardTitle>
			</CardHeader>
			<CardContent>
				{content ? (
					content.text
				) : (
					<span>Click on a field to see more information.</span>
				)}
			</CardContent>
		</Card>
	)
}
