import React from "react"
import { TrendingUp } from "lucide-react"
import { Bar, BarChart, XAxis } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface ContentFunnelProps {
	data: Array<{ month: string; conversion: number; traffic: number }>
}

const chartConfig: ChartConfig = {
	conversion: {
		label: "Conversion",
		color: "#6F00FF",
	},
	traffic: {
		label: "Traffic",
		color: "#E4CFFF",
	},
}

export const ContentFunnel: React.FC<ContentFunnelProps> = ({ data }) => {
	return (
		<Card className="flex flex-col justify-between">
			<CardHeader>
				<CardTitle>Content Funnel</CardTitle>
				<CardDescription>January - May 2024</CardDescription>
			</CardHeader>
			<CardContent>
				<ChartContainer config={chartConfig}>
					<BarChart accessibilityLayer data={data}>
						<XAxis
							dataKey="month"
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							tickFormatter={value => value.slice(0, 3)}
						/>
						<ChartTooltip content={<ChartTooltipContent hideLabel />} />
						<ChartLegend content={<ChartLegendContent />} />
						<Bar
							dataKey="conversion"
							stackId="a"
							fill="var(--color-conversion)"
							radius={[0, 0, 4, 4]}
						/>
						<Bar
							dataKey="traffic"
							stackId="a"
							fill="var(--color-traffic)"
							radius={[4, 4, 0, 0]}
						/>
					</BarChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 items-start text-sm">
				<div className="flex gap-2 font-medium leading-none">
					Trending up by 5.2% this month <TrendingUp className="w-4 h-4" />
				</div>
				<div className="leading-none text-muted-foreground">
					Showing top 5 content and their revenue
				</div>
			</CardFooter>
		</Card>
	)
}
