import React from "react"
import { TrendingUp } from "lucide-react"
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface MonthlyEarningsProps {
	data: Array<{ month: string; current: number; past: number }>
}

const chartConfig: ChartConfig = {
	current: {
		label: "Current Month",
		color: "#99C74E",
	},
	past: {
		label: "Past Month",
		color: "#617F31",
	},
}

export const MonthlyEarnings: React.FC<MonthlyEarningsProps> = ({ data }) => (
	<Card className="col-span-2">
		<CardHeader>
			<CardTitle>Monthly Earnings</CardTitle>
			<CardDescription>Comparing current month to past month</CardDescription>
		</CardHeader>
		<CardContent>
			<ChartContainer config={chartConfig}>
				<AreaChart
					data={data}
					margin={{
						left: 12,
						right: 12,
					}}
				>
					<CartesianGrid vertical={false} />
					<XAxis
						dataKey="month"
						tickLine={false}
						axisLine={false}
						tickMargin={8}
						tickFormatter={value => value.slice(0, 3)}
					/>
					<ChartTooltip cursor={false} content={<ChartTooltipContent />} />
					<defs>
						<linearGradient id="fillCurrent" x1="0" y1="0" x2="0" y2="1">
							<stop
								offset="5%"
								stopColor="var(--color-current)"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="var(--color-current)"
								stopOpacity={0.1}
							/>
						</linearGradient>
						<linearGradient id="fillPast" x1="0" y1="0" x2="0" y2="1">
							<stop
								offset="5%"
								stopColor="var(--color-past)"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="var(--color-past)"
								stopOpacity={0.1}
							/>
						</linearGradient>
					</defs>
					<Area
						dataKey="past"
						type="natural"
						fill="url(#fillPast)"
						fillOpacity={0.4}
						stroke="var(--color-past)"
						stackId="a"
					/>
					<Area
						dataKey="current"
						type="natural"
						fill="url(#fillCurrent)"
						fillOpacity={0.4}
						stroke="var(--color-current)"
						stackId="a"
					/>
				</AreaChart>
			</ChartContainer>
		</CardContent>
		<CardFooter className="flex-col gap-2 items-start text-sm">
			<div className="flex gap-2 font-medium leading-none">
				Trending up by 5.2% this month <TrendingUp className="w-4 h-4" />
			</div>
			<div className="leading-none text-muted-foreground">
				Comparing current year to past year
			</div>
		</CardFooter>
	</Card>
)
