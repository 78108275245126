import React from "react"
import { ArrowUpRight } from "lucide-react"
import { Link } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/Avatar"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"

interface RecentSale {
	name: string
	email: string
	amount: string
}

interface RecentSalesProps {
	recentSales: RecentSale[]
}

export const RecentSales: React.FC<RecentSalesProps> = ({ recentSales }) => {
	return (
		<Card>
			<CardHeader className="flex flex-row items-center">
				<div className="grid gap-2">
					<CardTitle>Recent Sales</CardTitle>
					<CardDescription>You made 265 sales this month.</CardDescription>
				</div>
				<Button size="sm" className="gap-1 ml-auto">
					<Link to="#" className="flex flex-row gap-2 items-center">
						View All
						<ArrowUpRight className="w-4 h-4" />
					</Link>
				</Button>
			</CardHeader>
			<CardContent>
				<div className="space-y-8">
					{recentSales.map((sale, index) => (
						<div key={index} className="flex items-center">
							<Avatar className="w-9 h-9">
								<AvatarImage src="/avatars/01.png" alt="Avatar" />
								<AvatarFallback>
									{sale.name
										.split(" ")
										.map(n => n[0])
										.join("")}
								</AvatarFallback>
							</Avatar>
							<div className="ml-4 space-y-1">
								<p className="text-sm font-medium leading-none">{sale.name}</p>
								<p className="text-sm text-muted-foreground">{sale.email}</p>
							</div>
							<div className="ml-auto font-medium">{sale.amount}</div>
						</div>
					))}
				</div>
			</CardContent>
		</Card>
	)
}
