import { useParams } from "react-router-dom"
import { BotSettings } from "src/components/organisms/BotSetting"
import Persona from "src/components/organisms/Persona"
import Scenarios from "src/components/organisms/Scenarios"

export const PersonaDetailScreen: React.FC = () => {
	const { persona_id } = useParams<{ persona_id: string }>()

	if (!persona_id) {
		return <div>Persona not found</div>
	}

	return (
		<div className="flex flex-col gap-2 p-4 w-full min-h-screen bg-muted/40">
			<Persona persona_id={persona_id} />
			<Scenarios persona_id={persona_id} />
			<BotSettings persona_id={persona_id} />
		</div>
	)
}
