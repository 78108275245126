import React, { useState } from "react"
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/Avatar"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Label } from "src/components/ui/Label"
import trpc_client from "src/lib/trpc"

import { PublicSafePersona } from "../../../../../api/src/models/persona/persona.schema"
import { UpdatePersonaDetails } from "../../../../../api/src/models/persona/persona_details.schema"
import {
	BestDays,
	BestHours,
	ContentFunnel,
	FanCategorization,
	Keywords,
	MonthlyEarnings,
	PopularContent,
	RecentSales,
	RevenueMetrics,
	Stats,
	TopCustomersTable,
	UnmatchedDesires,
} from "../Dashboard"

import {
	bestDaysData,
	bestHoursData,
	contentFunnelData,
	fanCategorizationData,
	keywordsData,
	monthlyEarningsData,
	popularContentData,
	recentSalesData,
	revenueMetricsData,
	statsData,
	topCustomersData,
	unmatchedDesiresData,
} from "./mockData"
import { PersonaQuestionsCard } from "./PersonaQuestionsCard"

interface PersonaComponentProps {
	persona: PublicSafePersona | null
	isLoading: boolean
	updatePersona: (data: Partial<PublicSafePersona>) => Promise<void>
}

const PersonaComponent: React.FC<PersonaComponentProps> = ({
	persona,
	isLoading,
	updatePersona,
}) => {
	const [showAll, setShowAll] = useState(false)
	if (isLoading) {
		return <div>Loading persona...</div>
	}

	if (!persona) {
		return <div>Persona not found</div>
	}

	const handleUpdateDetails = async (data: Partial<UpdatePersonaDetails>) => {
		try {
			await trpc_client.persona.updateDetails.mutate({
				persona_id: persona.id,
				data,
			})
			// Optionally, you can refetch the persona data here to update the UI
			// await refetchPersona()
		} catch (error) {
			console.error("Failed to update persona details:", error)
			// Handle error (e.g., show an error message to the user)
		}
	}

	return (
		<div className="flex flex-col gap-4">
			<Card className="w-full bg-stone-white">
				<CardHeader>
					<Avatar className="size-24">
						{persona.avatar_image ? (
							<AvatarImage
								src={persona.avatar_image}
								alt={persona.name ?? "Avatar"}
							/>
						) : (
							<AvatarFallback>
								{persona.name && persona.name.charAt(0).toUpperCase()}
							</AvatarFallback>
						)}
					</Avatar>
					<CardTitle>{persona.name}</CardTitle>
				</CardHeader>
				<CardContent>
					<Label>About:</Label>
					<p className="para text-stone-root">{persona.about}</p>

					<Label>Physical Description:</Label>
					<p className="para text-stone-root">{persona.physical_description}</p>

					<Label>Tone:</Label>
					<p className="para text-stone-root">{persona.tone}</p>

					<Label>Social Media:</Label>
					<ul className="pl-5 list-disc">
						{persona.reddit && <li>Reddit: {persona.reddit}</li>}
						{persona.twitter && <li>Twitter: {persona.twitter}</li>}
						{persona.instagram && <li>Instagram: {persona.instagram}</li>}
					</ul>

					{persona.website && (
						<>
							<Label>Website:</Label>
							<p className="para text-stone-root">{persona.website}</p>
						</>
					)}
				</CardContent>

				<Card className="m-4 bg-stone-pale">
					<CardHeader>
						<CardTitle>Analytics for this persona</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col flex-1 gap-4 p-4">
						<Stats data={statsData} />

						{showAll && (
							<>
								<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
									<MonthlyEarnings data={monthlyEarningsData} />
									<PopularContent data={popularContentData} />
								</div>

								<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
									<TopCustomersTable top_customers={topCustomersData} />
									<RecentSales recentSales={recentSalesData} />
									<Keywords data={keywordsData} />
									<ContentFunnel data={contentFunnelData} />
									<RevenueMetrics data={revenueMetricsData} />
								</div>

								<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
									<BestDays data={bestDaysData} />
									<BestHours data={bestHoursData} />
									<UnmatchedDesires data={unmatchedDesiresData} />
								</div>

								<FanCategorization
									revenueData={fanCategorizationData.revenueData}
									desireData={fanCategorizationData.desireData}
									locationData={fanCategorizationData.locationData}
									languageData={fanCategorizationData.languageData}
									ageRangeData={fanCategorizationData.ageRangeData}
								/>
							</>
						)}

						<Button variant="ghost" onClick={() => setShowAll(!showAll)}>
							{showAll ? "Show Less" : "Show More"}
						</Button>
					</CardContent>
				</Card>
			</Card>
			<PersonaQuestionsCard
				initialData={persona}
				personaId={persona.id}
				onUpdate={handleUpdateDetails}
			/>
		</div>
	)
}

export default PersonaComponent
