import { ExternalLink, MoreHorizontal } from "lucide-react"
import { Link } from "react-router-dom"
import { TelegramPreview } from "src/components/organisms/BotSetting/TelegramPreview"
import { Alert, AlertDescription, AlertTitle } from "src/components/ui/Alert"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import { Status } from "src/components/ui/Status"
import { SubmitButton } from "src/components/ui/SubmitButton"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "src/components/ui/Tabs"

import {
	PublicSafePersona,
	PublicSafeTelegramBot,
} from "../../../../../api/src/models/persona/persona.schema"
import { Textarea } from "../../ui/Textarea"

export const Settings = ({
	persona,
	telegram_bot,
	isLoading,
	isEditing,
	botData,
	setIsEditing,
	updateBotData,
	handleBotCreation,
	handleBotUpdate,
}: {
	persona: PublicSafePersona
	telegram_bot: PublicSafeTelegramBot | null
	isLoading: boolean
	isEditing: boolean
	botData: {
		displayName: string
		bio: string
		description: string
	}
	setIsEditing: (value: boolean) => void
	updateBotData: (field: keyof typeof botData) => (value: string) => void
	handleBotCreation: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
	handleBotUpdate: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}) => {
	return (
		<Card className="w-full bg-stone-white">
			<CardHeader className="flex flex-row justify-between">
				<CardTitle className="flex flex-row gap-2 items-center mt-2">
					Your Bot
					<Status
						variant={telegram_bot ? "success" : "warning"}
						text={telegram_bot ? "Live" : "Draft"}
					/>
				</CardTitle>
				{telegram_bot && (
					<Link to={`https://t.me/${telegram_bot?.user_name}`}>
						<Button variant="outline">
							Go to Telegram Chat <ExternalLink className="ml-2 w-4 h-4" />
						</Button>
					</Link>
				)}
			</CardHeader>
			{telegram_bot ? (
				<CardContent className="grid grid-cols-3 gap-8">
					<form
						className="flex flex-col col-span-2 gap-2"
						onSubmit={handleBotUpdate}
					>
						<div>
							<Label htmlFor="username">Username</Label>
							<Input
								type="text"
								id="username"
								name="username"
								value={telegram_bot.user_name ?? ""}
								disabled
								readOnly
							/>
						</div>
						<div>
							<Label htmlFor="displayName">Display Name</Label>
							<Input
								type="text"
								id="displayName"
								name="displayName"
								value={botData.displayName}
								onChange={e => updateBotData("displayName")(e.target.value)}
								readOnly={!isEditing}
								disabled={!isEditing}
							/>
						</div>
						<div>
							<Label htmlFor="description">
								Description{" "}
								<span className="text-stone-light">
									(shown on the &apos;chat preview&apos; tab)
								</span>
							</Label>
							<Textarea
								id="description"
								name="description"
								value={botData.description}
								onChange={e => updateBotData("description")(e.target.value)}
								readOnly={!isEditing}
								disabled={!isEditing}
							/>
						</div>
						<div>
							<Label htmlFor="bio">Bio</Label>
							<Textarea
								id="bio"
								name="bio"
								value={botData.bio}
								onChange={e => updateBotData("bio")(e.target.value)}
								readOnly={!isEditing}
								disabled={!isEditing}
							/>
						</div>

						{isEditing ? (
							<div className="flex gap-2">
								<SubmitButton is_loading={isLoading} type="submit">
									Update Bot
								</SubmitButton>
								<Button variant="outline" onClick={() => setIsEditing(false)}>
									Cancel
								</Button>
							</div>
						) : (
							<Button variant="outline" onClick={() => setIsEditing(true)}>
								Edit
							</Button>
						)}
					</form>
					<div>
						<Alert variant="info" className="mb-4">
							<AlertTitle>Preview</AlertTitle>
							<AlertDescription>
								A preview of what the end user will see when interacting with
								your bot.
							</AlertDescription>
						</Alert>
						<Tabs defaultValue="profile" className="h-full">
							<TabsList className="grid grid-cols-2 w-full">
								<TabsTrigger value="profile">Profile Preview</TabsTrigger>
								<TabsTrigger value="chat">Chat Preview</TabsTrigger>
							</TabsList>
							<TabsContent value="profile">
								<TelegramPreview
									displayName={botData.displayName}
									handle={telegram_bot.user_name ?? ""}
									bio={botData.bio}
									files={[persona.avatar_image ?? "/no_image.jpg"]}
								/>
							</TabsContent>
							<TabsContent value="chat" className="flex h-auto">
								<Card className="flex flex-col flex-1 justify-between h-full border border-stone-pale">
									<div className="flex justify-between items-center p-2 border-b border-stone-pale">
										<div className="flex items-center">
											<div className="overflow-hidden relative rounded-full aspect-square size-12">
												<img
													src={persona.avatar_image ?? "/no_image.jpg"}
													className="object-cover w-full h-full"
													alt="Avatar"
												/>
											</div>
											<p className="ml-3 text-stone-root">{persona.name}</p>
										</div>
										<MoreHorizontal className="w-4 h-4 text-stone-root" />
									</div>
									<p className="p-4 m-8 my-60 rounded-xl detail bg-stone-pale text-stone-root">
										{botData.description}
									</p>
									<div className="flex justify-end border-t border-stone-pale">
										<Button className="m-2 w-full bg-stone-light">Start</Button>
									</div>
								</Card>
							</TabsContent>
						</Tabs>
					</div>
				</CardContent>
			) : (
				<CardContent className="grid grid-cols-3 gap-8">
					<form className="col-span-2" onSubmit={handleBotCreation}>
						<div className="space-y-2">
							<h4 className="bold">How to setup a Telegram bot:</h4>
							<ol className="pl-8 list-decimal">
								<li>
									Open{" "}
									<Link
										className="underline text-blue-root bold"
										to="https://t.me/botfather"
									>
										BotFather
									</Link>{" "}
									or search for the BotFather in Telegram.
								</li>
								<li>
									Start a chat and send{" "}
									<pre className="inline-block">/newbot</pre> to create a new
									bot.
								</li>
								<li>
									Follow the prompts to choose a name and username for your bot.
								</li>
								<li>
									Copy the <b>API token</b> provided by BotFather.
								</li>
							</ol>
						</div>

						<div className="grid gap-4 mt-8">
							<div>
								<Label htmlFor="persona_id">Persona ID</Label>
								<Input
									type="text"
									id="persona_id"
									name="persona_id"
									value={persona.id}
									disabled
									readOnly
								/>
							</div>
							<div>
								<Label htmlFor="token">API Token</Label>
								<Input type="text" id="token" name="token" required />
							</div>
							<SubmitButton is_loading={isLoading} type="submit">
								Submit
							</SubmitButton>
						</div>
					</form>
				</CardContent>
			)}
		</Card>
	)
}
