import {
	Briefcase,
	Calendar,
	Compass,
	Feather,
	Footprints,
	GraduationCap,
	Heart,
	Home,
	LandPlot,
	Languages,
	LucideIcon,
	MapPin,
	MessageCircle,
	Music,
	Palette,
	Pizza,
	PlaneTakeoff,
	Ruler,
	Tag,
	Tv,
	User,
	Users,
	Utensils,
} from "lucide-react"
import { TooltipContent } from "src/components/ui/TooltipCard"

const createTooltipContent = (
	icon: LucideIcon,
	description: string,
	examples: string[]
): TooltipContent => ({
	icon,
	text: (
		<>
			{description}
			<span>
				<br />
				<br />
				E.g:
				<br />
				<br />
				<ul className="pl-4 list-disc bold">
					{examples.map((example, index) => (
						<li key={index}>{example}</li>
					))}
				</ul>
			</span>
		</>
	),
})

export const tooltipContent: Record<string, TooltipContent> = {
	name: createTooltipContent(User, "Enter the persona's full name.", [
		"John Doe",
		"Jane Smith",
	]),
	age: createTooltipContent(Calendar, "Enter the persona's age in years.", [
		"25",
		"32",
	]),
	height: createTooltipContent(Ruler, "Enter the persona's height.", [
		"5'10\"",
		"175 cm",
	]),
	birthday: createTooltipContent(Calendar, "Enter the persona's birthday.", [
		"25/12/1988",
		"DD/MM/YYYY",
	]),
	home_town: createTooltipContent(Home, "Enter the persona's home town.", [
		"New York City, NY",
		"London, UK",
	]),
	current_location: createTooltipContent(
		MapPin,
		"Enter the persona's current location.",
		["Los Angeles, CA", "Paris, France"]
	),
	education: createTooltipContent(
		GraduationCap,
		"Enter the persona's educational background.",
		["Bachelor's in Psychology", "High School Diploma"]
	),
	profession_job: createTooltipContent(
		Briefcase,
		"Enter the persona's profession or job.",
		["Software Engineer", "Freelance Writer"]
	),
	language: createTooltipContent(
		Languages,
		"Enter the languages the persona speaks.",
		["English, Spanish", "Mandarin, French"]
	),
	relationship_status: createTooltipContent(
		Users,
		"Enter the persona's relationship status.",
		["Single", "In a relationship", "Married"]
	),
	shoe_size: createTooltipContent(
		Footprints,
		"Enter the persona's shoe size.",
		["US 9", "EU 42"]
	),
	cock_size: createTooltipContent(Ruler, "Enter the persona's cock size.", [
		"7 inches",
		"18 cm",
	]),
	model_niche: createTooltipContent(
		Tag,
		"Enter the persona's modeling niche.",
		["Fashion", "Fitness", "Glamour"]
	),
	tattoos: createTooltipContent(
		Feather,
		"Describe the persona's tattoos, if any.",
		["Butterfly on left shoulder", "Tribal sleeve on right arm"]
	),
	hobbies: createTooltipContent(Heart, "List the persona's hobbies.", [
		"Photography, hiking",
		"Cooking, reading",
	]),
	who_do_you_live_with: createTooltipContent(
		Users,
		"Describe who the persona lives with.",
		[
			"Parents and younger sister",
			"Two roommates in a shared apartment",
			"Alone in a studio flat",
		]
	),
	what_are_your_favourite_restaurants_to_go_to: createTooltipContent(
		Utensils,
		"List the persona's favourite restaurants to go to.",
		[
			'The local Italian trattoria, "Pasta Paradise"',
			'A trendy vegan cafe called "Green Eats"',
			"The family-owned sushi bar downtown",
		]
	),
	do_you_often_go_on_nights_out_if_so_where_do_you_go: createTooltipContent(
		Music,
		"Describe the persona's night out habits.",
		[
			"Frequent visits to jazz clubs in the city center",
			"Occasional karaoke nights with coworkers",
			"Prefers quiet evenings at home with friends",
		]
	),
	do_you_have_any_upcoming_holiday_plans: createTooltipContent(
		PlaneTakeoff,
		"Describe any upcoming holiday plans.",
		[
			"Two-week road trip along the Pacific Coast Highway",
			"Yoga retreat in Bali next month",
			"Planning a winter ski trip to the Swiss Alps",
		]
	),
	what_is_your_idea_of_a_perfect_date: createTooltipContent(
		Heart,
		"Describe the persona's idea of a perfect date.",
		[
			"Cooking a gourmet meal together at home",
			"Hiking to a scenic viewpoint for a sunset picnic",
			"Attending a live comedy show followed by dessert",
		]
	),
	where_is_the_best_place_that_you_have_visited: createTooltipContent(
		MapPin,
		"Enter the best place the persona has visited.",
		[
			"The ancient ruins of Petra, Jordan",
			"The vibrant streets of Tokyo, Japan",
			"The serene beaches of the Maldives",
		]
	),
	music: createTooltipContent(
		Music,
		"List the persona's favorite music genres or artists.",
		[
			"90s grunge and alternative rock",
			"Classical composers like Chopin and Debussy",
			"Modern pop artists such as Dua Lipa and The Weeknd",
		]
	),
	tv_film: createTooltipContent(
		Tv,
		"List the persona's favorite TV shows and films.",
		[
			"TV: Breaking Bad, The Office, Stranger Things",
			"Films: The Shawshank Redemption, Inception, The Grand Budapest Hotel",
		]
	),
	food: createTooltipContent(
		Pizza,
		"List the persona's favorite foods or cuisines.",
		[
			"Authentic Thai street food",
			"Homemade Italian pasta dishes",
			"Gourmet burgers with unusual toppings",
		]
	),
	color: createTooltipContent(
		Palette,
		"Enter the persona's favorite color(s).",
		[
			"Deep forest green",
			"Pastel pink and lavender",
			"Navy blue and gold combination",
		]
	),
	sports: createTooltipContent(
		LandPlot,
		"List the persona's favorite sports or physical activities.",
		[
			"Rock climbing and bouldering",
			"Playing soccer in a local league",
			"Practicing hot yoga three times a week",
		]
	),
	future_plans: createTooltipContent(
		Compass,
		"Describe the persona's future plans or aspirations.",
		[
			"Launch a sustainable fashion brand within the next two years",
			"Complete a PhD in Marine Biology and conduct deep-sea research",
			"Save up to buy a small farm and live a self-sufficient lifestyle",
		]
	),
	about: createTooltipContent(
		MessageCircle,
		"Provide a brief description about the persona.",
		[
			"A passionate environmentalist with a quirky sense of humor",
			"Tech-savvy introvert who loves solving complex puzzles",
			"Free-spirited artist always seeking new forms of creative expression",
		]
	),
	physical_description: createTooltipContent(
		User,
		"Provide a physical description of the persona.",
		[
			"Tall and lean with wavy auburn hair and striking green eyes",
			"Petite build with a warm smile and a distinctive birthmark on the left cheek",
			"Athletic physique with sun-kissed skin and a sleeve of colorful tattoos",
		]
	),

	gender: createTooltipContent(User, "Select the persona's gender.", [
		"Male",
		"Female",
	]),
	boob_size: createTooltipContent(Ruler, "Enter the persona's boob size.", [
		"32B",
		"34C",
		"36D",
	]),
	waist_size: createTooltipContent(Ruler, "Enter the persona's waist size.", [
		"24 inches",
		"28 inches",
		"30 inches",
	]),
}
