import React from "react"
import { Link } from "react-router-dom"
import { Button } from "src/components/ui/Button"
import { useAuth } from "src/providers/AuthProvider"

import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../../components/ui/Card"
import { Input } from "../../../components/ui/Input"
import { Label } from "../../../components/ui/Label"

export const SettingsScreen = () => {
	const { user } = useAuth()

	return (
		<div className="flex flex-col gap-2 p-4 w-full min-h-screen bg-muted/40">
			<Card>
				<CardHeader>
					<CardTitle>Details</CardTitle>
				</CardHeader>
				<CardContent>
					<form className="max-w-md">
						<div className="mb-4">
							<Label htmlFor="name" className="block mb-2">
								First and Last Name
							</Label>
							<div className="flex flex-row gap-2">
								<Input
									type="text"
									id="name"
									value={user?.given_name}
									disabled
								/>
								<Input
									type="text"
									id="name"
									value={user?.family_name}
									disabled
								/>
							</div>
						</div>
						<div className="mb-4">
							<Label htmlFor="email" className="block mb-2">
								Email
							</Label>
							<Input type="email" id="email" value={user?.email} disabled />
						</div>
					</form>
				</CardContent>
				<CardFooter>
					<Link to="mailto:hello@ofa.app?subject=update account details">
						<Button variant="outline">Contact</Button>
					</Link>
				</CardFooter>
			</Card>
		</div>
	)
}
