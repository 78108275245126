import { useEffect } from "react"
import { useContentTable } from "src/hooks/useContentTable"

import ContentTableComponent from "./ContentTable.component"
export interface ContentAggregateMetadata {
	total_files: number
}

interface ContentTableContainerProps {
	content_set_id: string
	persona_id: string
	onUpdate?: (metadata: ContentAggregateMetadata) => void
	isEditing: boolean
	toggleEditing?: () => void
}

const ContentTable = ({
	content_set_id,
	persona_id,
	onUpdate,
	isEditing,
	toggleEditing,
}: ContentTableContainerProps) => {
	const { content, is_loading, onDelete, onFileUploaded, onError } =
		useContentTable(content_set_id, persona_id)

	useEffect(() => {
		onUpdate && onUpdate({ total_files: content.length })
	}, [content, onUpdate])

	return (
		<ContentTableComponent
			content_set_id={content_set_id}
			persona_id={persona_id}
			content={content}
			onDelete={onDelete}
			onFileUploaded={onFileUploaded}
			upload_config={{
				maxFileSize: 10000000,
				allowedFileTypes: ["image/jpeg", "image/png", "image/gif"],
			}}
			onError={onError}
			isEditing={isEditing}
			toggleEditing={toggleEditing}
			is_loading={is_loading}
		/>
	)
}

export default ContentTable
