import React from "react"

import ContentRowComponent from "./ContentRow.component"
import useUpdateContent, { ContentItem } from "./useUpdateContent"

interface ContentRowProps {
	content_set_id: string
	item: ContentItem
	onDelete: (content_id: string) => void
	isEditing: boolean
}

const ContentRow: React.FC<ContentRowProps> = ({
	item,
	content_set_id,
	onDelete,
	isEditing,
}) => {
	const {
		content,
		handleUpdateContent,
		deleteContent,
		is_updating,
		is_deleting,
		update_error,
		errors,
		update_info,
		loading_state,
	} = useUpdateContent(content_set_id, item, onDelete)

	return (
		<ContentRowComponent
			content={content}
			updateContent={handleUpdateContent}
			deleteContent={deleteContent}
			is_updating={is_updating}
			is_deleting={is_deleting}
			update_error={update_error}
			errors={errors}
			update_info={update_info}
			loading_state={loading_state}
			isEditing={isEditing}
		/>
	)
}

export default ContentRow
