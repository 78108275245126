import ScenariosComponent from "./Scenarios.component"
import { useScenarios } from "./useScenarios"

const Scenarios = ({ persona_id }: { persona_id: string }) => {
	const { scenarios, is_loading, refetchScenarios } = useScenarios(persona_id)

	return (
		<ScenariosComponent
			persona_id={persona_id}
			scenarios={scenarios}
			is_loading={is_loading}
			refetchScenarios={refetchScenarios}
		/>
	)
}

export default Scenarios
