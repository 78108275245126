import { createTRPCProxyClient, httpBatchLink } from "@trpc/client"
import SuperJSON from "superjson"

import type { RPCRouter } from "../../../api/src/routes/api.routes"

const REACT_APP_API_URL = process.env.REACT_APP_API_URL as string
//     👆 **type-only** import
// Pass AppRouter as generic here. 👇 This lets the `trpc` object know
// what procedures are available on the server and their input/output types.
const trpc_client: ReturnType<typeof createTRPCProxyClient<RPCRouter>> =
	createTRPCProxyClient<RPCRouter>({
		links: [
			httpBatchLink({
				url: `${REACT_APP_API_URL}/trpc`,
				fetch(url, options) {
					return fetch(url, {
						...options,
						credentials: "include",
					})
				},
			}),
		],
		transformer: SuperJSON,
	})

export default trpc_client
