import React, { memo, useMemo, useState } from "react"
import { ImageUp } from "lucide-react"
import { Card, CardContent } from "src/components/ui/Card"
import {
	Carousel,
	CarouselApi,
	CarouselContent,
	CarouselItem,
} from "src/components/ui/Carousel"
import { Separator } from "src/components/ui/Separator"

const TelegramPreview = memo(
	({
		displayName,
		handle,
		bio,
		files,
	}: {
		displayName: string
		handle: string
		bio: string
		files: string[]
	}) => {
		const [current, setCurrent] = useState(0)
		const [api, setApi] = useState<CarouselApi>()
		const count = files.length

		React.useEffect(() => {
			if (!api) return
			api.on("select", () => {
				setCurrent(api.selectedScrollSnap())
			})
		}, [api])

		const carouselContent = useMemo(
			() => (
				<CarouselContent>
					{files.map(file => (
						<CarouselItem key={file} className="w-full">
							<div className="overflow-hidden relative aspect-square">
								<img
									src={file}
									alt={`Uploaded file ${files.indexOf(file) + 1}`}
									className="object-cover w-full h-full"
								/>
							</div>
						</CarouselItem>
					))}
				</CarouselContent>
			),
			[files]
		)

		return (
			<Card className="overflow-hidden w-full h-fit bg-stone-pale">
				<CardContent className="p-0">
					<Carousel className="relative w-full" setApi={setApi}>
						{files.length > 0 ? (
							<>
								<div className="flex absolute top-0 right-0 left-0 z-20 justify-center p-2">
									<div className={`flex gap-1 w-full grid-cols-${count}`}>
										{files.map((_, index) => (
											<div
												key={index}
												className={`h-1 w-full rounded-full ${
													index === current
														? "bg-stone-white"
														: "bg-stone-pale/50"
												}`}
											/>
										))}
									</div>
								</div>
								{carouselContent}
							</>
						) : (
							<div className="flex flex-col gap-2 justify-start items-center p-4 aspect-square bg-stone-light text-stone-pale">
								<ImageUp />
								<p className="">No images uploaded yet</p>
							</div>
						)}

						{/* Telegram UI */}
						<section className="absolute bottom-0 p-4 w-full text-stone-pale">
							<p className="truncate bold subheader">
								{displayName ? `${displayName}` : "name"}
							</p>
							<p className="mb-2 detail">last seen recently</p>
						</section>
					</Carousel>

					<div>
						<div className="flex flex-col gap-2 p-4 rounded-2xl bg-stone-pale">
							<p className="detail">about</p>
							<p className="truncate bold">{bio ? bio : "bio"}</p>
							<Separator />
							<p className="detail">username</p>
							<p className="truncate bold">
								{handle ? `@${handle}` : "@name_ofa_bot"}
							</p>
						</div>
					</div>
				</CardContent>
			</Card>
		)
	}
)

TelegramPreview.displayName = "TelegramPreview"

export { TelegramPreview }
