"use client"

import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface FanCategorizationProps {
	revenueData: { category: string; value: number }[]
	desireData: { category: string; value: number }[]
	locationData: { category: string; value: number }[]
	languageData: { category: string; value: number }[]
	ageRangeData: { category: string; value: number }[]
}

const RadarChartComponent: React.FC<{
	data: { category: string; value: number }[]
	title: string
	color: string
}> = ({ data, title, color }) => (
	<Card className="col-span-1 p-8">
		<CardHeader className="items-center pb-2">
			<CardTitle className="detail bold text-stone-root">{title}</CardTitle>
		</CardHeader>
		<CardContent className="p-8 pb-0">
			<ChartContainer
				config={{ value: { label: "Value", color } }}
				className="mx-auto aspect-square"
			>
				<RadarChart data={data}>
					<ChartTooltip cursor={false} content={<ChartTooltipContent />} />
					<PolarAngleAxis dataKey="category" />
					<PolarGrid />
					<Radar
						dataKey="value"
						fill={color}
						className="mix-blend-multiply"
						fillOpacity={0.6}
					/>
				</RadarChart>
			</ChartContainer>
		</CardContent>
	</Card>
)

export const FanCategorization: React.FC<FanCategorizationProps> = ({
	revenueData,
	desireData,
	locationData,
	languageData,
	ageRangeData,
}) => {
	return (
		<Card className="col-span-full">
			<CardHeader className="pb-4">
				<CardTitle>Fan Categorization</CardTitle>
				<CardDescription>Multiple metrics</CardDescription>
			</CardHeader>
			<CardContent className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
				<RadarChartComponent
					data={revenueData}
					title="By Revenue"
					color="#FF4A57"
				/>
				<RadarChartComponent
					data={desireData}
					title="By Desire"
					color="#005EFF"
				/>
				<RadarChartComponent
					data={locationData}
					title="By Location"
					color="#99C74E"
				/>
				<RadarChartComponent
					data={languageData}
					title="By Language"
					color="#6F00FF"
				/>
				<RadarChartComponent
					data={ageRangeData}
					title="By Age Range"
					color="#FFD700"
				/>
			</CardContent>
		</Card>
	)
}
