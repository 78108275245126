import React from "react"
import { cn } from "src/lib/utils"

export interface StepItem {
	step: number
	label: string
	path?: string
}

interface StepProps {
	steps: StepItem[]
	currentStep: number
	className?: string
}

export const Steps: React.FC<StepProps> = ({
	steps,
	currentStep,
	className,
}) => {
	return (
		<div
			className={cn(
				"flex justify-center items-center p-2 py-8 w-full",
				className
			)}
		>
			<div className="flex relative gap-8 justify-center items-center w-full max-w-2xl">
				{steps.map(({ step, label }, index) => {
					const isCompleted = index < currentStep
					const isCurrent = index === currentStep

					return (
						<div
							key={step}
							className="relative z-10 flex flex-col items-center gap-1.5"
						>
							<div
								className={cn(
									"flex justify-center items-center w-8 h-8 rounded-full",
									isCompleted
										? "text-white bg-green-mid"
										: isCurrent
										? "bg-stone-root text-stone-pale"
										: "bg-white text-muted-foreground"
								)}
							>
								{step}
							</div>
							<span
								className={cn(
									"text-center detail",
									isCurrent
										? "bold text-stone-root"
										: isCompleted
										? "text-green-mid"
										: "text-stone-light"
								)}
							>
								{label}
							</span>
						</div>
					)
				})}
			</div>
		</div>
	)
}
