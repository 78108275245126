import {
	Home,
	LayoutDashboard,
	LogOutIcon,
	PersonStanding,
	Settings,
} from "lucide-react"
import { Link } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "src/providers/AuthProvider"

import { cn } from "../../lib/utils"
import { Button } from "../ui/Button"

const NAV_ITEMS = [
	{ href: "/", label: "Home", icon: Home },
	{ href: "/dashboard", label: "Dashboard", icon: LayoutDashboard },
	{ href: "/persona", label: "Persona", icon: PersonStanding },
	{ href: "/settings", label: "Settings", icon: Settings },
	{ href: "/logout", icon: LogOutIcon, label: "" },
]

const DashboardNav = ({ location }) => (
	<nav className="flex gap-1 justify-between w-full rounded-full">
		{NAV_ITEMS.map(({ href, label, icon: Icon }) => (
			<Link
				key={href}
				to={href}
				className={cn(
					"flex items-center justify-center gap-1 px-4 py-2 rounded-full transition-colors text-stone-dark hover:bg-stone-pale",
					location.pathname === href && "bg-stone-white text-stone-dark",
					href === "/logout" ? "w-auto" : "w-full"
				)}
			>
				<Icon className="w-4 h-4" />
				{href !== "/logout" && href !== "/" && <p>{label}</p>}
			</Link>
		))}
	</nav>
)

const MainNav = ({ user, navigate }) => (
	<ul className="flex gap-2 justify-between w-full h-full lg:justify-start lg:gap-1">
		<li className="flex justify-center items-center px-4 py-2">
			<a href="/" className="flex lg:hidden">
				<span className="text-2xl font-black text-stone-root">OFA</span>
			</a>
			<a href="/" className="hidden lg:flex">
				Home
			</a>
		</li>
		{["features", "process", "pricing", "blog"].map(section => (
			<li
				key={section}
				className="hidden justify-center items-center px-4 py-2 rounded-full hover:bg-stone-pale lg:flex"
			>
				<a href={`/#${section}`}>
					{section.charAt(0).toUpperCase() + section.slice(1)}
				</a>
			</li>
		))}
		<li className="flex justify-center items-center">
			{user ? (
				<Button
					className="w-auto"
					variant="secondary"
					onClick={() => navigate("/dashboard")}
				>
					My Dashboard
				</Button>
			) : (
				<Button className="w-auto" onClick={() => navigate("/login")}>
					Login
				</Button>
			)}
		</li>
	</ul>
)

export const Header = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { user } = useAuth()
	const isDashboard = ["/dashboard", "/persona", "/content", "/settings"].some(
		path => location.pathname.includes(path)
	)

	return (
		<header className="flex justify-between items-center px-10 py-4 mx-auto w-full max-w-5xl bg-transparent">
			<div className="flex fixed inset-x-0 top-6 z-50 justify-center items-center">
				<div className="flex overflow-hidden flex-col justify-center items-center p-1 w-full bg-white rounded-full border-2 transition-all border-stone-pale lg:w-auto">
					{isDashboard ? (
						<DashboardNav location={location} />
					) : (
						<MainNav user={user} navigate={navigate} />
					)}
				</div>
			</div>
		</header>
	)
}
