import { ChevronRight } from "lucide-react"

import { CTA } from "../ui/CTA"

const footerNavs = [
	{
		label: "Product",
		items: [
			{
				href: "/#features",
				name: "Features",
			},
			{
				href: "/#process",
				name: "Process",
			},
			{
				href: "/#testimonials",
				name: "Testimonials",
			},
			{
				href: "/#pricing",
				name: "Pricing",
			},
			{
				href: "/#faq",
				name: "FAQ",
			},
		],
	},
	{
		label: "Company",
		items: [
			{
				href: "/blog",
				name: "Blog",
			},

			{
				href: "mailto:hello@ofa.app",
				name: "Contact",
			},
		],
	},

	// {
	//   label: "Legal",
	//   items: [
	//     {
	//       href: "/privacy",
	//       name: "Privacy Policy",
	//     },
	//     {
	//       href: "/terms",
	//       name: "Terms of Service",
	//     },
	//   ],
	// },
]

export const Footer = () => {
	return (
		<footer className="p-16">
			<div className="px-4 mx-auto w-full max-w-screen-xl">
				<div className="gap-4 p-4 py-16 sm:pb-16 md:flex md:justify-between">
					<div className="flex flex-col gap-4 mb-12">
						<a href="/" className="flex gap-2 items-center">
							<span className="text-4xl font-black text-stone-root">OFA</span>
						</a>
						<div className="max-w-sm">
							<div className="flex z-10 flex-col items-start w-full text-left">
								<p className="pb-6 subheader text-stone-root">
									Drive PPV sales with AI chatters.
									<br />
									Get started today.
								</p>
								<CTA />
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-8 sm:grid-cols-4 sm:gap-6">
						{footerNavs.map(nav => (
							<div key={nav.label}>
								<h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
									{nav.label}
								</h2>
								<ul className="grid gap-2">
									{nav.items.map(item => (
										<li key={item.name}>
											<a
												href={item.href}
												className="inline-flex gap-1 justify-start items-center text-gray-400 duration-200 cursor-pointer group hover:text-gray-600 hover:opacity-90 dark:text-gray-400 dark:hover:text-gray-200"
											>
												{item.name}
												<ChevronRight className="w-4 h-4 opacity-0 transition-all duration-300 ease-out transform translate-x-0 group-hover:translate-x-1 group-hover:opacity-100" />
											</a>
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>

				<div className="flex flex-col gap-2 py-4 border-t sm:flex sm:flex-row sm:items-center sm:justify-between">
					<span className="text-sm text-stone-light sm:text-center">
						Copyright © {new Date().getFullYear()}{" "}
						<a href="/" className="cursor-pointer">
							OFA
						</a>
						. All Rights Reserved.
					</span>
				</div>
			</div>
		</footer>
	)
}
