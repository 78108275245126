import { ReactNode } from "react"
import { cn } from "src/lib/utils"

const BentoGrid = ({
	children,
	className,
}: {
	children: ReactNode
	className?: string
}) => {
	return (
		<div
			className={cn(
				"grid overflow-hidden grid-cols-3 gap-2 w-full auto-rows-[22rem]",
				className
			)}
		>
			{children}
		</div>
	)
}

const BentoCard = ({
	name,
	className,
	background,
	description,
}: {
	name: string
	className: string
	background: ReactNode
	description: ReactNode
}) => (
	<div
		key={name}
		className={cn(
			"flex overflow-hidden relative flex-col col-span-3 justify-between bg-white rounded-3xl group",
			className
		)}
	>
		<div className="z-0">{background}</div>
		<div className="flex z-10 flex-col gap-1 p-6 pt-0 transform-gpu pointer-events-none">
			<h3 className="text-xl font-semibold text-stone-700 dark:text-stone-300">
				{name}
			</h3>
			<p className="max-w-lg text-stone-400">{description}</p>
		</div>
	</div>
)

export { BentoCard, BentoGrid }
