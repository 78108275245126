import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ContentAggregateMetadata } from "src/components/organisms/ContentTable"
import ScenarioForm from "src/components/organisms/Scenarios/ScenarioForm.component"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"

export const ScenarioScreen: React.FC = () => {
	const [searchParams] = useSearchParams()
	const persona_id = searchParams.get("persona_id")

	if (!persona_id) {
		throw new Error("Persona ID is required")
	}

	const [upload_metadata, setUploadMetadata] =
		useState<ContentAggregateMetadata>({
			total_files: 0,
		})

	return (
		<section className="flex flex-col gap-4 md:flex-row">
			<Card className="w-full bg-stone-white">
				<CardHeader>
					<CardTitle>Create Your Scenario and add content</CardTitle>
					<CardDescription>
						Create your scenario with content to get started with your first
						bot.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<ScenarioForm
						isOpen={true}
						onClose={() => {}}
						persona_id={persona_id}
						isModal={false}
					/>
				</CardContent>
				{upload_metadata.total_files > 0 && (
					<CardDescription className="p-4 text-stone-dark">
						{upload_metadata.total_files} files uploaded. Click <b>Next</b> to
						continue.
					</CardDescription>
				)}
			</Card>
		</section>
	)
}
