import { useState } from "react"
import { ScenarioCard } from "src/components/organisms/Scenarios/ScenarioCard.component"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Separator } from "src/components/ui/Separator"

import { ContentSetJSON } from "../../../../../api/src/models/content/content_set.model"

import ScenarioForm from "./ScenarioForm.component"

const ScenariosComponent: React.FC<{
	persona_id: string
	scenarios?: ContentSetJSON[]
	is_loading?: boolean
	refetchScenarios?: () => void
	showAddButton?: boolean
}> = ({
	persona_id,
	scenarios = [],
	is_loading = false,
	refetchScenarios = () => {},
	showAddButton = true,
}) => {
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

	return (
		<Card className="w-full">
			<CardHeader className="flex flex-row justify-between items-center">
				<CardTitle>Scenarios</CardTitle>
				{showAddButton && (
					<Button variant="outline" onClick={() => setIsAddDialogOpen(true)}>
						Add
					</Button>
				)}
			</CardHeader>

			<CardContent className="flex overflow-visible flex-col gap-2">
				{is_loading ? (
					<div className="flex justify-center items-center h-32">
						<p>Loading scenarios...</p>
					</div>
				) : (
					<>
						<div className="flex flex-col gap-2">
							{scenarios
								.filter(s => s.status !== "archived")
								.map(scenario => (
									<ScenarioCard
										key={scenario.id}
										scenario={scenario}
										onUpdate={refetchScenarios}
									/>
								))}
						</div>
						<div className="flex flex-col gap-2">
							{scenarios
								.filter(s => s.status === "archived")
								.map(scenario => (
									<>
										<Separator className="my-4" />
										<ScenarioCard
											key={scenario.id}
											scenario={scenario}
											onUpdate={refetchScenarios}
										/>
									</>
								))}
						</div>
					</>
				)}
			</CardContent>
			{showAddButton && (
				<ScenarioForm
					isOpen={isAddDialogOpen}
					onClose={() => {
						setIsAddDialogOpen(false)
						refetchScenarios()
					}}
					persona_id={persona_id}
				/>
			)}
		</Card>
	)
}

export default ScenariosComponent
