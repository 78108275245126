import React, { useState } from "react"
import { File } from "lucide-react"
import {
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Status } from "src/components/ui/Status"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "src/components/ui/Tooltip"

import { ContentSetJSON } from "../../../../../api/src/models/content/content_set.model"

import { ScenarioDialog } from "./ScenarioDialog.component"

export const ScenarioCard = ({
	scenario,
	onUpdate,
}: {
	scenario: ContentSetJSON
	onUpdate: () => void
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	return (
		<>
			<Card
				className="flex flex-row gap-2 justify-between items-center p-2 w-full cursor-pointer bg-stone-pale"
				onClick={() => setIsDialogOpen(true)}
			>
				<CardHeader className="flex flex-row gap-4 items-center p-2">
					<Status
						variant={scenario.status === "archived" ? "neutral" : "success"}
						text={scenario.status === "archived" ? "Archived" : "Active"}
					/>
					<span className="text-stone-light">•</span>

					<CardTitle className="bold para">{scenario.title}</CardTitle>
					<span className="text-stone-light">•</span>

					<CardDescription className="max-w-xs">
						<Tooltip>
							<TooltipTrigger asChild>
								<span className="line-clamp-1">{scenario.description}</span>
							</TooltipTrigger>
							<TooltipContent className="max-w-[320px]">
								<p className="para">{scenario.description}</p>
							</TooltipContent>
						</Tooltip>
					</CardDescription>
					<span className="text-stone-light">•</span>
					<CardDescription className="flex flex-row gap-1 items-center">
						{scenario.contents.length} <File className="w-4 h-4" />
					</CardDescription>
				</CardHeader>
			</Card>
			<ScenarioDialog
				scenario={scenario}
				persona_id={scenario.persona_id}
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				onUpdate={onUpdate}
			/>
		</>
	)
}
