import React from "react"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"

interface UnmatchedDesiresProps {
	data: string[]
}

export const UnmatchedDesires: React.FC<UnmatchedDesiresProps> = ({ data }) => {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Unmatched Desires</CardTitle>
				<CardDescription>
					Desires that are not matched with any customer
				</CardDescription>
			</CardHeader>
			<CardContent>
				<ul className="pl-5 space-y-2 list-disc">
					{data.map((desire, index) => (
						<li key={index}>{desire}</li>
					))}
				</ul>
			</CardContent>
		</Card>
	)
}
