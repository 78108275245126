import { StarFilledIcon } from "@radix-ui/react-icons"
import { cn } from "src/lib/utils"

import { SectionHeader } from "./SectionHeader"

export const Highlight = ({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<span
			className={cn(
				"bg-orange-root/20 p-1 py-0.5 font-bold text-orange-mid",
				className
			)}
		>
			{children}
		</span>
	)
}

export interface TestimonialCardProps {
	name: string
	role: string
	description: React.ReactNode
	className?: string
	[key: string]: any
}

export const TestimonialCard = ({
	description,
	name,
	role,
	className,
	...props // Capture the rest of the props
}: TestimonialCardProps) => (
	<div
		className={cn(
			"flex w-72 shrink-0 cursor-pointer snap-center snap-always flex-col justify-between rounded-xl p-8 lg:min-w-96",
			// light styles
			"border border-stone-200 bg-white",

			className
		)}
		{...props} // Spread the rest of the props here
	>
		<div className="select-none text-stone-mid">{description}</div>

		<div className="select-none">
			<div className="flex flex-row py-1">
				<StarFilledIcon className="size-4 text-orange-root" />
				<StarFilledIcon className="size-4 text-orange-root" />
				<StarFilledIcon className="size-4 text-orange-root" />
				<StarFilledIcon className="size-4 text-orange-root" />
				<StarFilledIcon className="size-4 text-orange-root" />
			</div>
			<p className="text-stone-root">{name}</p>
			<p className="text-sm font-normal text-stone-400">{role}</p>
		</div>
	</div>
)

const testimonials = [
	{
		name: "Jordan Smith",
		role: "Agency Owner",
		description: (
			<p className="subheader">
				Only Fans Agent&apos;s solution has skyrocketed our engagement!{" "}
				<Highlight>
					Available 24/7, it makes every fan feel valued—an essential in our
					toolkit.
				</Highlight>
			</p>
		),
	},
	{
		name: "Sophia Liu",
		role: "Marketing Director",
		description: (
			<p className="subheader">
				Thanks to Only Fans Agent, we&apos;ve completely transformed how we
				connect with our fans. Their{" "}
				<Highlight>
					insights help us tailor our content perfectly, boosting engagement and
					making each fan feel truly special.
				</Highlight>
			</p>
		),
	},
	{
		name: "Alex Martinez",
		role: "Digital Strategist",
		description: (
			<p className="subheader">
				Using Only Fans Agent&apos;s autopilot tool has really paid off,
				diversifying and growing our revenue. The AI effortlessly manages our
				resources, <Highlight>helping us sell more and engage better</Highlight>
				—it&apos;s been a fantastic investment.
			</p>
		),
	},
	{
		name: "Emily Rivera",
		role: "Content Creator",
		description: (
			<p className="subheader">
				The personalized content suggestions from Only Fans Agent have been a
				game changer.{" "}
				<Highlight>
					The AI understands our audience&apos;s preferences, enabling us to
					create highly engaging content.
				</Highlight>
			</p>
		),
	},
	{
		name: "Michael Zhou",
		role: "Operations Manager",
		description: (
			<p className="subheader">
				Only Fans Agent has streamlined our operations, making fan management
				smooth and efficient.{" "}
				<Highlight>
					The automation tools save us time and help us focus on growth.
				</Highlight>
			</p>
		),
	},
	{
		name: "Laura Gibson",
		role: "PR Specialist",
		description: (
			<p className="subheader">
				Our public relations strategy has improved significantly with the
				insights from Only Fans Agent.{" "}
				<Highlight>
					Their detailed analytics allow us to make informed decisions that
					resonate with our audience.
				</Highlight>
			</p>
		),
	},
]

export const Testimonials = () => {
	return (
		<section id="testimonials" className="p-8">
			<div className="rounded-2xl bg-stone-pale py-14">
				<div className="mx-auto">
					<SectionHeader
						subheader="Testimonials"
						title="What other agencies are saying"
						description="Discover how Only Fans AI Chatter Agents are revolutionizing fan engagement."
					/>

					<div className="relative mx-auto mt-6 max-w-[100vw] overflow-hidden">
						<div
							className={cn(
								"flex w-full snap-x snap-mandatory flex-row gap-6 overflow-x-auto py-14",

								// no scrollbar
								"[-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
							)}
						>
							{/* Empty placeholder  */}
							<div className="size-72 shrink-0 md:h-60 md:min-w-96"></div>
							{testimonials.map((card, idx) => (
								<TestimonialCard {...card} key={idx} />
							))}
							{/* Empty placeholder  */}
							<div className="size-72 shrink-0 md:h-60 md:min-w-96"></div>
						</div>
						<div className="pointer-events-none absolute inset-y-0 left-0 hidden h-full w-1/5 bg-gradient-to-r from-stone-pale md:block"></div>
						<div className="pointer-events-none absolute inset-y-0 right-0 hidden h-full w-1/5 bg-gradient-to-l from-stone-pale md:block"></div>
					</div>
				</div>
			</div>
		</section>
	)
}
