import React, { useState } from "react"
import { toast } from "sonner"
import { Card } from "src/components/ui/Card"
import { Status } from "src/components/ui/Status"

import { ContentSetJSON } from "../../../../../api/src/models/content/content_set.model"
import { Button } from "../../../components/ui/Button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "../../../components/ui/Dialog"
import ContentTable from "../ContentTable"

import { ScenarioDetailsCard } from "./ScenarioDetailsCard.component"
import { useScenarios } from "./useScenarios"

export const ScenarioDialog: React.FC<{
	scenario: ContentSetJSON
	isOpen: boolean
	onClose: () => void
	persona_id: string
	onUpdate: () => void
}> = ({ scenario, isOpen, onClose, persona_id, onUpdate }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [editedScenario, setEditedScenario] = useState(scenario)
	const { updateScenario, toggleScenarioArchived } = useScenarios(persona_id)

	const handleSave = async () => {
		try {
			await updateScenario(scenario.id, {
				title: editedScenario.title,
				description: editedScenario.description,
				conversation_starter: editedScenario.conversation_starter,
			})
			setIsEditing(false)
			onUpdate()
			onClose()
			toast.success("Scenario updated successfully")
		} catch (error) {
			console.error("Failed to update scenario:", error)
			toast.error("Failed to update scenario. Please try again.")
		}
	}

	const handleToggleArchived = async () => {
		try {
			await toggleScenarioArchived(scenario.id)
			onUpdate()
			onClose()
			toast.success(
				`Scenario ${
					scenario.status === "archived" ? "activated" : "archived"
				} successfully`
			)
		} catch (error) {
			console.error("Failed to toggle scenario archived status:", error)
			toast.error("Failed to update scenario status. Please try again.")
		}
	}

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="max-w-[80%] max-h-[80%] overflow-y-scroll bg-stone-pale">
				<DialogHeader>
					<Status
						variant={scenario.status === "archived" ? "neutral" : "success"}
						text={scenario.status === "archived" ? "Archived" : "Active"}
					/>
				</DialogHeader>
				<div className="flex flex-col gap-4">
					<ScenarioDetailsCard
						scenario={editedScenario}
						isEditing={isEditing}
						onEdit={setEditedScenario}
					/>
					<Card>
						<ContentTable
							content_set_id={scenario.id}
							persona_id={scenario.persona_id}
							isEditing={isEditing}
						/>
					</Card>
				</div>
				<DialogFooter className="sm:justify-start">
					<Button
						className="w-full sm:w-1/2"
						variant={isEditing ? "secondary" : "outline"}
						onClick={() => {
							if (isEditing) {
								setEditedScenario(scenario)
							}
							setIsEditing(!isEditing)
						}}
					>
						{isEditing ? "Cancel" : "Edit"}
					</Button>
					<Button
						className="w-full sm:w-1/2"
						variant={
							isEditing
								? "default"
								: scenario.status === "archived"
								? "default"
								: "destructive"
						}
						onClick={() => {
							if (isEditing) {
								handleSave()
							} else {
								handleToggleArchived()
							}
						}}
					>
						{isEditing
							? "Save"
							: scenario.status === "archived"
							? "Activate"
							: "Archive"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
