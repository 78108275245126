const action_types = [
	{ value: "aftercare", label: "Aftercare" },
	{ value: "anal", label: "Anal" },
	{ value: "ass", label: "Ass" },
	{ value: "bdsm", label: "BDSM" },
	{ value: "blowjob", label: "Blowjob" },
	{ value: "creampie", label: "Creampie" },
	{ value: "cum-shot", label: "Cum shot" },
	{ value: "feet", label: "Feet" },
	{ value: "fetish", label: "Fetish" },
	{ value: "foreplay", label: "Foreplay" },
	{ value: "group-play", label: "Group play" },
	{ value: "lingerie", label: "Lingerie" },
	{ value: "orgy", label: "Orgy" },
	{ value: "outdoor", label: "Outdoor" },
	{ value: "public", label: "Public" },
	{ value: "pussy", label: "Pussy" },
	{ value: "role-play", label: "Role play" },
	{ value: "small-talk", label: "Small Talk" },
	{ value: "solo-play", label: "Solo play" },
	{ value: "squirting", label: "Squirting" },
	{ value: "threesome", label: "Threesome" },
	{ value: "toys", label: "Toys" },
	{ value: "voyeur", label: "Voyeur" },
]

export default action_types
