import { Link } from "react-router-dom"
import { CALL_BOOKING_URL } from "src/lib/constants"
import { useAuth } from "src/providers/AuthProvider"

import { Button } from "./Button"

export const CTA = () => {
	const { user } = useAuth()

	return (
		<div className="flex flex-col gap-2 md:flex-row">
			<Link to={user ? `/${user}/dashboard` : "/register"}>
				<Button
					size="lg"
					variant="default"
					className="flex flex-row gap-2 items-center w-full"
				>
					{user ? "Go to dashboard" : "Get started"}
				</Button>
			</Link>
			<Link
				to={CALL_BOOKING_URL}
				target="_blank"
				className="flex flex-col items-center w-full"
			>
				<Button
					size="lg"
					variant="outline"
					className="flex flex-row gap-2 items-center w-full text-stone-root"
				>
					Book a 15 min call
				</Button>
				<p className="mt-2 subscript text-stone-light">With the founders</p>
			</Link>
		</div>
	)
}
