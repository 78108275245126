import React from "react"

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../ui/Card"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../ui/Table"

interface TopCustomerData {
	customer: string
	email: string
	totalOrders: number
	lastOrder: string
	totalSpent: number
}

interface TopCustomersTableProps {
	top_customers: TopCustomerData[]
}

export const TopCustomersTable: React.FC<TopCustomersTableProps> = ({
	top_customers,
}) => {
	return (
		<Card className="xl:col-span-2">
			<CardHeader className="flex flex-row items-center">
				<div className="grid gap-2">
					<CardTitle>Top Customers</CardTitle>
					<CardDescription>Your most valuable customers.</CardDescription>
				</div>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Customer</TableHead>
							<TableHead>Total Orders</TableHead>
							<TableHead>Total Spent</TableHead>
							<TableHead className="text-right">Last Order</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{top_customers.map((customer, index) => (
							<TableRow key={`${customer.customer}-${index}`}>
								<TableCell>
									<div className="font-medium">{customer.customer}</div>
									<div className="hidden text-sm text-muted-foreground md:inline">
										{customer.email}
									</div>
								</TableCell>
								<TableCell>{customer.totalOrders}</TableCell>
								<TableCell>${customer.totalSpent.toFixed(2)}</TableCell>
								<TableCell className="text-right">
									{customer.lastOrder}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}
