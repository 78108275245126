import React from "react"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"

interface StatsProps {
	data: Array<{
		title: string
		value: string
		change: string
		icon: React.ElementType
	}>
}

const StatCard: React.FC<{
	stat: StatsProps["data"][0]
	total: number
}> = ({ stat, total }) => {
	return (
		<Card className="overflow-hidden">
			<CardHeader className="flex flex-row justify-between items-center pb-2 space-y-0">
				<CardTitle className="text-stone-light detail">{stat.title}</CardTitle>
				<stat.icon className="w-4 h-4 text-stone-light" />
			</CardHeader>
			<CardContent>
				<div className="flex justify-between items-center">
					<div>
						<div className="H3 text-stone-mid">{stat.value}</div>
						<p className="text-xs text-stone-light">{stat.change}</p>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export const Stats: React.FC<StatsProps> = ({ data }) => {
	const total = data.reduce(
		(acc, stat) => acc + parseFloat(stat.value.replace(/[^0-9.-]+/g, "")),
		0
	)

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
			{data.map(stat => (
				<StatCard key={stat.title} stat={stat} total={total} />
			))}
		</div>
	)
}
