import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "sonner"

import { Input } from "../../components/ui/Input"
import { SubmitButton } from "../../components/ui/SubmitButton"
import { useAuth } from "../../providers/AuthProvider"

const Login = () => {
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState("")
	const [is_loading, setIsLoading] = useState(false)
	const { user, login } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (user) {
			navigate("/dashboard")
		}
	}, [user, navigate])

	const validateEmail = (email: string) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!re.test(email)) {
			setEmailError("Please enter a valid email address")
			return false
		}
		setEmailError("")
		return true
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (!validateEmail(email)) return

		setIsLoading(true)
		try {
			await login(email)
			toast.success("Magic link sent! Check your email.")
		} catch (error) {
			console.error("Login error:", error)
			if (error instanceof Error) {
				if (error.message === "User not found") {
					toast.error("User not found. Please register first.")
				} else {
					toast.error(
						error.message || "An error occurred during login. Please try again."
					)
				}
			} else {
				toast.error("An unexpected error occurred. Please try again.")
			}
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="py-32 w-full lg:grid">
			<div className="flex justify-center items-center py-12">
				<div className="mx-auto grid w-[350px] gap-6">
					<div className="grid gap-2 text-center">
						<h1 className="H1">Login</h1>
						<p className="text-balance text-muted-foreground">
							Enter your email below to receive a magic link.
						</p>
					</div>
					<form className="flex flex-col gap-2" onSubmit={handleSubmit}>
						<div className="flex flex-col gap-1">
							<Input
								name="email"
								id="email"
								type="email"
								placeholder="Enter your email"
								required
								value={email}
								onChange={e => {
									setEmail(e.target.value)
									validateEmail(e.target.value)
								}}
								className={emailError ? "border-red-root" : ""}
							/>
							{emailError && (
								<span className="text-sm text-red-root">{emailError}</span>
							)}
						</div>
						<SubmitButton
							type="submit"
							is_loading={is_loading}
							disabled={!!emailError}
						>
							<span>Sign in with Magic Link ✨</span>
						</SubmitButton>
					</form>
					<div className="flex gap-1 justify-center items-center">
						<p>Don&apos;t have an account?</p>
						<Link to="/register">
							<span className="font-medium underline">Sign up</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
