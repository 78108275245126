import { useState } from "react"
import { Loader2, Plus } from "lucide-react"
import ReactS3Uploader, { S3Response } from "react-s3-uploader"
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/Avatar"

import { Alert } from "./Alert"

export function ProfilePictureUpload({
	onImageChange,
	getSignedUrl,
}: {
	onImageChange: (base64: string | null) => void
	getSignedUrl: (file: File, callback: (params: S3Response) => void) => void
}) {
	const [image, setImage] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	const [uploadInProgress, setUploadInProgress] = useState(false)

	let uploader

	const handleUploadStart = () => {
		setUploadInProgress(true)
	}

	const handleUploadFinish = (result: S3Response) => {
		setImage(result.publicUrl)
		onImageChange(result.publicUrl)
		setUploadInProgress(false)
	}

	const handleUploadError = (error: string) => {
		setError(error)
		console.error("Error uploading image:", error)
	}

	return (
		<>
			<div onClick={() => uploader?.click()} className="cursor-pointer">
				<Avatar className="w-20 h-20">
					{image ? (
						<AvatarImage src={image} />
					) : uploadInProgress ? (
						<AvatarFallback>
							<Loader2 className="w-4 h-4 animate-spin" />
						</AvatarFallback>
					) : (
						<AvatarFallback>
							<Plus className="w-4 h-4" />
						</AvatarFallback>
					)}
				</Avatar>
				<ReactS3Uploader
					inputRef={ref => (uploader = ref)}
					className="hidden"
					getSignedUrl={getSignedUrl}
					accept="image/*"
					onProgress={handleUploadStart}
					onError={handleUploadError}
					onFinish={handleUploadFinish}
					uploadRequestHeaders={{
						"x-amz-acl": "public-read",
					}}
					contentDisposition="auto"
				/>
			</div>
			{error && <Alert variant="destructive">err:{error}</Alert>}
		</>
	)
}
