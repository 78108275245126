import { Badge } from "./Badge"

type StatusVariant = "success" | "warning" | "error" | "neutral"

interface StatusProps {
	variant: StatusVariant
	text: string
}

const getVariantClasses = (variant: StatusVariant) => {
	switch (variant) {
		case "success":
			return "border-green-mid text-green-mid"
		case "warning":
			return "border-yellow-root text-yellow-root"
		case "error":
			return "border-red-root text-red-root"
		case "neutral":
			return "border-stone-light text-stone-light"
	}
}

export const Status = ({ variant, text }: StatusProps) => {
	const variantClasses = getVariantClasses(variant)

	return (
		<Badge variant="outline" className={`w-fit ${variantClasses}`}>
			<span className="flex relative w-2 h-2">
				{variant === "success" && (
					<span className="inline-flex absolute w-full h-full rounded-full opacity-75 animate-ping bg-green-mid"></span>
				)}
				<span
					className={`inline-flex relative w-2 h-2 rounded-full ${variantClasses.replace(
						"border-",
						"bg-"
					)}`}
				></span>
			</span>
			<p className="ml-2 detail">{text}</p>
		</Badge>
	)
}
