import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "src/lib/utils"

const alertVariants = cva(
	"relative w-full rounded-2xl px-4 py-3 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7",
	{
		variants: {
			variant: {
				default: "bg-background text-foreground",
				destructive: "text-red-mid bg-red-pale/50 [&>svg]:text-red-mid",
				info: "text-blue-mid bg-blue-pale/50 [&>svg]:text-blue-mid",
				success: "text-green-mid bg-green-pale/50 [&>svg]:text-green-mid",
				warning: "text-orange-mid bg-orange-pale/50 [&>svg]:text-orange-mid",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

const Alert = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
	<div
		ref={ref}
		role="alert"
		className={cn(alertVariants({ variant }), className)}
		{...props}
	/>
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
	<h5
		ref={ref}
		className={cn("bold mb-1 leading-none tracking-tight", className)}
		{...props}
	/>
))
AlertTitle.displayName = "AlertTitle"

const AlertDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("text-sm [&_p]:leading-relaxed", className)}
		{...props}
	/>
))
AlertDescription.displayName = "AlertDescription"

export { Alert, AlertDescription, AlertTitle }
