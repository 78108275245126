import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "src/providers/AuthProvider"

const Logout = () => {
	const { logout } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		const performLogout = async () => {
			try {
				await logout()
				navigate("/")
			} catch (error) {
				console.error("Logout error:", error)
				// Optionally, you can handle the error (e.g., show an error message)
			}
		}

		performLogout()
	}, [logout, navigate])

	return (
		<div className="w-full py-32 flex items-center justify-center">
			<div className="text-center">
				<h1 className="H1">Logging out...</h1>
				<p className="text-muted-foreground mt-2">
					Please wait while we log you out.
				</p>
			</div>
		</div>
	)
}

export default Logout
