import React from "react"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import { Textarea } from "src/components/ui/Textarea"

import { ContentSetJSON } from "../../../../../api/src/models/content/content_set.model"

interface ScenarioDetailsCardProps {
	scenario: ContentSetJSON
	isEditing: boolean
	onEdit: (updatedScenario: ContentSetJSON) => void
}

export const ScenarioDetailsCard: React.FC<ScenarioDetailsCardProps> = ({
	scenario,
	isEditing,
	onEdit,
}) => {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Details</CardTitle>
			</CardHeader>
			<CardContent className="grid gap-4">
				<div>
					<Label htmlFor="title">Title</Label>
					<Input
						id="title"
						value={scenario.title}
						onChange={e => onEdit({ ...scenario, title: e.target.value })}
						readOnly={!isEditing}
						disabled={!isEditing}
					/>
				</div>
				<div>
					<Label htmlFor="description">Description</Label>
					<Textarea
						id="description"
						value={scenario.description}
						onChange={e => onEdit({ ...scenario, description: e.target.value })}
						readOnly={!isEditing}
						disabled={!isEditing}
					/>
				</div>
				<div>
					<Label htmlFor="conversation_starter">Conversation Starter</Label>
					<Textarea
						id="conversation_starter"
						value={scenario.conversation_starter}
						onChange={e =>
							onEdit({ ...scenario, conversation_starter: e.target.value })
						}
						readOnly={!isEditing}
						disabled={!isEditing}
					/>
				</div>
			</CardContent>
		</Card>
	)
}
