import * as React from "react"
import { Command as CommandPrimitive } from "cmdk"
import { X } from "lucide-react"
import { Badge } from "src/components/ui/Badge"
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
} from "src/components/ui/Command"

export type MultiSelectorOptions = Record<"value" | "label", string>

interface MultiSelectorProps {
	values: MultiSelectorOptions[]
	onValuesChange: (values: MultiSelectorOptions[]) => void
	options: MultiSelectorOptions[]
}

export const MultiSelector: React.FC<MultiSelectorProps> = ({
	values,
	onValuesChange,
	options,
}) => {
	const inputRef = React.useRef<HTMLInputElement>(null)
	const [open, setOpen] = React.useState(false)
	const [inputValue, setInputValue] = React.useState("")

	const handleUnselect = React.useCallback(
		(value: MultiSelectorOptions) => {
			onValuesChange(values.filter(s => s.value !== value.value))
		},
		[values, onValuesChange]
	)

	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			const input = inputRef.current
			if (input) {
				if (e.key === "Delete" || e.key === "Backspace") {
					if (input.value === "") {
						onValuesChange(values.slice(0, -1))
					}
				}
				if (e.key === "Escape") {
					input.blur()
				}
			}
		},
		[values, onValuesChange]
	)

	// Filter out the already selected options
	const selectables = options.filter(
		option => !values.some(value => value.value === option.value)
	)

	return (
		<Command
			onKeyDown={handleKeyDown}
			className="overflow-visible relative bg-transparent"
		>
			<div className="overflow-y-auto px-3 py-2 max-h-20 text-sm rounded-2xl border group min-h-12 border-input bg-background ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
				<div className="flex flex-wrap gap-1">
					{values.map(value => {
						return (
							<Badge key={value.value} variant="secondary">
								{value.label}
								<button
									className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
									onKeyDown={e => {
										if (e.key === "Enter") {
											handleUnselect(value)
										}
									}}
									onMouseDown={e => {
										e.preventDefault()
										e.stopPropagation()
									}}
									onClick={() => handleUnselect(value)}
								>
									<X className="w-3 h-3 text-muted-foreground hover:text-foreground" />
								</button>
							</Badge>
						)
					})}
					<CommandPrimitive.Input
						ref={inputRef}
						value={inputValue}
						onValueChange={setInputValue}
						onBlur={() => setOpen(false)}
						onFocus={() => setOpen(true)}
						className="flex-1 ml-2 bg-transparent outline-none placeholder:text-muted-foreground"
						placeholder="Select action types..."
					/>
				</div>
			</div>
			<div className="relative">
				<CommandList>
					{open && selectables.length > 0 ? (
						<div className="absolute top-0 left-0 z-10 w-full rounded-2xl border shadow-md outline-none bg-popover text-popover-foreground animate-in">
							<CommandGroup className="max-h-[200px] overflow-y-auto">
								{selectables.map(selectable => {
									return (
										<CommandItem
											key={selectable.value}
											onMouseDown={e => {
												e.preventDefault()
												e.stopPropagation()
											}}
											onSelect={() => {
												setInputValue("")
												onValuesChange([...values, selectable])
											}}
											className="p-4 rounded-xl cursor-pointer"
										>
											{selectable.label}
										</CommandItem>
									)
								})}
							</CommandGroup>
						</div>
					) : null}
				</CommandList>
			</div>
		</Command>
	)
}
