import React from "react"
import { Route, Routes } from "react-router-dom"

import { DashboardScreen } from "./dashboard/dashboard.screen"
import { PersonasScreen } from "./persona/persona.screen"
import { PersonaDetailScreen } from "./persona/personaDetail.screen"
import { SettingsScreen } from "./settings/settings.screen"

export const DashboardRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="dashboard" element={<DashboardScreen />} />
			<Route path="persona" element={<PersonasScreen />} />
			<Route path="persona/:persona_id" element={<PersonaDetailScreen />} />
			<Route path="settings" element={<SettingsScreen />} />
		</Routes>
	)
}
