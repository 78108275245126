import { cubicBezier, motion } from "framer-motion"

export function Grow() {
	const variant1 = {
		initial: {
			viewBox: "0 -950 366 1408",
			filter: "saturate(0.3)",
			opacity: 0.5,
			transition: {
				delay: 0.05,
				duration: 0.3,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			viewBox: "0 -60 366 310",
			filter: "saturate(1)",
			opacity: 1,
			transition: {
				delay: 0.05,
				duration: 0.3,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}
	const variant2 = {
		initial: {
			y: 0,
			transition: {
				delay: 0.05,
				duration: 0.3,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			y: 0,
			transition: {
				delay: 0.05,
				duration: 0.3,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}

	const containerVariants = {
		initial: {},
		whileHover: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	}

	return (
		<div className="relative h-full w-full transform-gpu bg-white">
			<motion.div
				variants={containerVariants}
				initial="initial"
				whileHover="whileHover"
				className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
			>
				<div className="relative flex h-full w-full cursor-pointer items-center justify-center overflow-hidden rounded-t-xl bg-transparent p-4">
					<div className="relative h-[200px] w-full cursor-pointer overflow-hidden rounded-xl border border-slate-200/50 bg-white dark:border-stone-700/50 dark:bg-stone-900 md:h-[80px]">
						<motion.p
							variants={variant2}
							className="absolute left-5 top-5 w-fit text-[15px]"
						>
							+845%
						</motion.p>
						<motion.svg
							variants={variant1}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							preserveAspectRatio="none"
							className="absolute inset-0 h-full w-full"
							viewBox="0 -950 366 1408"
						>
							<path
								fill="url(#a)"
								d="M0 193c109.5 0 260.5-52.5 366-192.5v907H0V193Z"
							/>
							<defs>
								<linearGradient
									id="a"
									x1={183}
									x2={183}
									y1={0.5}
									y2={262}
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#CCFC44" />
									<stop offset={1} stopColor="#7D9F1C" />
								</linearGradient>
							</defs>
						</motion.svg>
					</div>
				</div>
			</motion.div>
		</div>
	)
}
