"use client"

import React from "react"
import { TrendingUp } from "lucide-react"
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface KeywordsProps {
	data: Array<{ keyword: string; count: number }>
}

const chartConfig = {
	count: {
		label: "Count",
		color: "#B8EFFF",
	},
	label: {
		color: "#000",
	},
} satisfies ChartConfig

export const Keywords: React.FC<KeywordsProps> = ({ data }) => {
	return (
		<Card className="flex flex-col justify-between">
			<CardHeader>
				<CardTitle>Keywords</CardTitle>
				<CardDescription>Top 5 keywords</CardDescription>
			</CardHeader>
			<CardContent>
				<ChartContainer config={chartConfig}>
					<BarChart
						accessibilityLayer
						data={data}
						layout="vertical"
						margin={{
							right: 16,
						}}
					>
						<CartesianGrid horizontal={false} />
						<YAxis
							dataKey="keyword"
							type="category"
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							hide
						/>
						<XAxis dataKey="count" type="number" hide />
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent indicator="line" />}
						/>
						<Bar
							dataKey="count"
							layout="vertical"
							fill="var(--color-count)"
							radius={4}
						>
							<LabelList
								dataKey="keyword"
								position="insideLeft"
								offset={8}
								className="fill-[--color-label] line-clamp-1 truncate hover:overflow-visible hover:whitespace-normal"
								fontSize={14}
							/>
							<LabelList
								dataKey="count"
								position="right"
								offset={8}
								className="fill-foreground"
								fontSize={16}
							/>
						</Bar>
					</BarChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 items-start text-sm">
				<div className="flex gap-2 font-medium leading-none">
					Trending up by 5.2% this month <TrendingUp className="w-4 h-4" />
				</div>
				<div className="leading-none text-muted-foreground">
					Showing top 5 keywords and their counts
				</div>
			</CardFooter>
		</Card>
	)
}
