import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { cn } from "src/lib/utils"

export const AnimatedList = React.memo(
	({
		className,
		children,
		delay = 1000,
	}: {
		className?: string
		children: React.ReactNode
		delay?: number
	}) => {
		const [index, setIndex] = useState(0)
		const childrenArray = React.Children.toArray(children)

		useEffect(() => {
			const interval = setInterval(() => {
				setIndex(prevIndex => (prevIndex + 1) % childrenArray.length)
			}, delay)

			return () => clearInterval(interval)
		}, [childrenArray.length, delay])

		const itemsToShow = useMemo(
			() => childrenArray.slice(0, index + 1).reverse(),
			[index, childrenArray]
		)

		return (
			<div className={`flex flex-col items-center gap-4 ${className}`}>
				<AnimatePresence>
					{itemsToShow.map(item => (
						<AnimatedListItem key={(item as ReactElement).key}>
							{item}
						</AnimatedListItem>
					))}
				</AnimatePresence>
			</div>
		)
	}
)

AnimatedList.displayName = "AnimatedList"

export function AnimatedListItem({ children }: { children: React.ReactNode }) {
	const animations = {
		initial: { scale: 0, opacity: 0 },
		animate: { scale: 1, opacity: 1, originY: 0 },
		exit: { scale: 0, opacity: 0 },
		transition: { type: "spring", stiffness: 350, damping: 40 },
	}

	return (
		<motion.div {...animations} layout className="mx-auto w-full">
			{children}
		</motion.div>
	)
}

interface Item {
	name: string
	description: string
	icon: string
	color: string
	time: string
}

let notifications = [
	{
		name: "Jake",
		description: "You are stunning in your latest photoshoot!",
		time: "2m ago",
		icon: "🌟",
		color: "#FFD700",
	},
	{
		name: "Angel",
		description: "Can't wait to see you at the fashion week!",
		time: "5m ago",
		icon: "👠",
		color: "#FF69B4",
	},
	{
		name: "Charlie",
		description: "Your style is so inspiring, thanks for the tips!",
		time: "10m ago",
		icon: "💄",
		color: "#C71585",
	},
	{
		name: "Jane",
		description: "Loved your interview on TV yesterday!",
		time: "15m ago",
		icon: "📺",
		color: "#8A2BE2",
	},
	{
		name: "Simon",
		description: "Your new product line is fabulous!",
		time: "20m ago",
		icon: "👜",
		color: "#FF4500",
	},
	{
		name: "Lola",
		description: "That new dance move was incredible!",
		time: "25m ago",
		icon: "💃",
		color: "#FF6347",
	},
	{
		name: "Bella",
		description: "Can't believe how amazing your last show was!",
		time: "30m ago",
		icon: "👏",
		color: "#FFC0CB",
	},
	{
		name: "Eva",
		description: "Your energy on stage is mesmerizing!",
		time: "35m ago",
		icon: "🔥",
		color: "#FFA500",
	},
	{
		name: "Sophia",
		description: "Loved your costume today, you looked stunning!",
		time: "40m ago",
		icon: "🎭",
		color: "#DB7093",
	},
	{
		name: "Isabella",
		description: "Your performance was breathtaking!",
		time: "45m ago",
		icon: "🌹",
		color: "#FF0000",
	},
]

notifications = Array.from({ length: 10 }, () => notifications).flat()

const Notification = ({ name, description, icon, color, time }: Item) => {
	return (
		<figure
			className={cn(
				"relative mx-auto min-h-fit w-full max-w-[400px] transform cursor-pointer overflow-hidden rounded-lg p-4",
				// animation styles
				"transition-all duration-200 ease-in-out hover:scale-[103%]",
				// light styles
				"bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
				// dark styles
				"transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]"
			)}
		>
			<div className="flex flex-row items-center gap-3">
				<div
					className="flex h-10 w-10 items-center justify-center rounded-2xl"
					style={{
						backgroundColor: color,
					}}
				>
					<span className="text-lg">{icon}</span>
				</div>
				<div className="flex flex-col overflow-hidden">
					<figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white">
						<span className="text-sm sm:text-lg">{name}</span>
						<span className="mx-1">·</span>
						<span className="text-xs text-gray-500">{time}</span>
					</figcaption>
					<p className="text-sm font-normal dark:text-white/60">
						{description}
					</p>
				</div>
			</div>
		</figure>
	)
}

export const Notifications = () => {
	return (
		<div className="relative flex h-full max-h-[200px] w-full transform-gpu flex-col justify-between overflow-hidden md:max-h-[600px]">
			<div className="flex items-center justify-center overflow-hidden">
				<AnimatedList>
					{notifications.map((item, idx) => (
						<Notification {...item} key={idx} />
					))}
				</AnimatedList>
			</div>
		</div>
	)
}
