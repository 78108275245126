import React, { useState } from "react"
import { LucideIcon, MinusCircle, PlusCircle } from "lucide-react"
import * as LucideIcons from "lucide-react"

import { SectionHeader } from "./SectionHeader"

type QuestionProps = {
	question: string
	answer: React.ReactNode
	icon?: keyof typeof LucideIcons | undefined
	iconColor?: string
}

const Question: React.FC<QuestionProps> = ({
	question,
	answer,
	icon,
	iconColor = "currentColor",
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const IconComponent = icon ? (LucideIcons[icon] as LucideIcon) : null

	return (
		<div className="mb-2 group">
			<div
				className="flex flex-none items-center list-none cursor-pointer group/item"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div
					className="bg-stone-white hover:bg-blue-pale hover:text-blue-root hover:before:bg-blue-pale rounded-3xl py-3 px-4 flex-grow mr-2 relative ml-5
                        before:content-[''] before:absolute before:z-[0] before:bottom-0 before:left-[-8px] before:h-5 before:w-5 before:bg-stone-white before:rounded-br-2xl
                        after:content-[''] after:absolute after:z-[1] after:bottom-0 after:left-[-10px] after:w-[10px] after:h-5 after:bg-stone-pale after:rounded-br-[10px]"
				>
					<div className="flex items-center">
						<span className="text-base font-medium">{question}</span>
						{IconComponent && (
							<div className="absolute top-[-5px] right-0 rotate-[15deg]">
								<IconComponent color={iconColor} size={24} fill={iconColor} />
							</div>
						)}
					</div>
				</div>
				<div className="relative w-5 h-5 scale-90 grow-0">
					{isOpen ? (
						<MinusCircle className="size-5 text-stone-light absolute transition-all duration-300 group-hover/item:text-blue-root group-hover/item:[stroke-width:2.5]" />
					) : (
						<PlusCircle className="size-5 text-stone-light absolute transition-all duration-300 group-hover/item:text-blue-root group-hover/item:[stroke-width:2.5]" />
					)}
				</div>
			</div>
			{isOpen && (
				<div className="flex justify-end mt-2">
					<div
						className="bg-blue-root text-stone-white p-3 rounded-3xl relative max-w-[80%]
                          before:content-[''] before:absolute before:z-[0] before:bottom-0 before:right-[-8px] before:h-5 before:w-5 before:bg-blue-root before:rounded-bl-2xl
                          after:content-[''] after:absolute after:z-[1] after:bottom-0 after:right-[-10px] after:w-[10px] after:h-5 after:bg-stone-pale after:rounded-bl-[10px]"
					>
						{answer}
					</div>
				</div>
			)}
		</div>
	)
}

const faqs = [
	{
		section: "General",
		qa: [
			{
				question: "What is a chatter on OnlyFans?",
				answer: (
					<span>
						An OnlyFans chatter is an individual (or AI-driven system)
						responsible for managing and interacting with fans on behalf of a
						content creator.
						<br />
						<br />
						The primary role is to:
						<ul className="list-disc list-inside">
							<li>Engage with subscribers</li>
							<li>Respond to messages</li>
							<li>Promote content to increase fan interaction and revenue</li>
						</ul>
						<br />
						Both human and <b>AI-powered chatters</b> are utilized to maintain
						consistent and personalized communication.
					</span>
				),
			},
			{
				question: "How do chatters interact with fans?",
				answer: (
					<span>
						Chatters, whether human or AI, typically follow guidelines or
						scripts provided by the creator to maintain consistency in tone and
						style.
						<br />
						<br />
						They:
						<ul className="list-disc list-inside">
							<li>Respond to messages</li>
							<li>Initiate conversations</li>
							<li>Encourage fans to purchase content or tips</li>
						</ul>
						<br />
						<i>AI chatter </i> can automate these interactions, providing
						real-time responses that keep fans engaged.
					</span>
				),
			},
			{
				question: "Why do OnlyFans creators use chatters?",
				icon: "MessageCircle",
				iconColor: "blue",
				answer: (
					<span>
						Creators use chatters, including human and AI chatters, to maintain
						a high level of engagement with their fans without having to be
						online all the time.
						<br />
						<br />
						This helps to:
						<ul className="list-disc list-inside">
							<li>Keep fans engaged</li>
							<li>Increase the likelihood of content sales</li>
							<li>Allow creators to focus more on producing content</li>
						</ul>
						<br />
						<b>AI-powered chatters</b> can significantly enhance fan retention
						by providing round-the-clock, personalised interactions.
					</span>
				),
			},
			{
				question:
					"Are fans aware that they are chatting with someone other than the creator?",
				answer: (
					<span>
						Generally, chatters aim to maintain the illusion that fans are
						interacting directly with the creator.
						<br />
						<br />
						When using <b>AI chatbots</b>, it’s much easier and safer to ensure
						that the interactions feel as personalized and authentic as possible
						to maintain fan satisfaction.
					</span>
				),
			},
			{
				question: "How are chatters paid?",
				answer: (
					<span>
						Chatters are usually compensated in one of the following ways:
						<ul className="list-disc list-inside">
							<li>Hourly rate</li>
							<li>Per message</li>
							<li>
								Percentage of the earnings generated through their interactions
							</li>
						</ul>
						<br />
						Compensation structures vary depending on the agreement with the
						creator or agency. For <b>AI chat systems</b>, the cost is typically
						factored into the overall service fee.
					</span>
				),
			},
		],
	},
	{
		section: "Use of AI",
		qa: [
			{
				question: "What are AI chatters?",
				answer: (
					<span>
						<b>AI chatters</b> are automated systems that use artificial
						intelligence to simulate real-time interactions with fans.
						<br />
						<br />
						These <i>AI-driven systems</i> craft personalized responses that
						align with the creator&apos;s style, helping to engage fans and
						drive sales even when the creator is offline.{" "}
						<b>AI chatters for OnlyFans</b> can manage multiple conversations
						simultaneously, ensuring that no fan interaction is missed.
					</span>
				),
			},
			{
				question: "Why would a creator choose AI chatters over human chatters?",
				icon: "HeartPulse",
				iconColor: "red",
				answer: (
					<span>
						<b>AI chatters</b> can:
						<ul className="list-disc list-inside">
							<li>Provide 24/7 engagement</li>
							<li>Handle 100&apos;s of conversations simultaneously</li>
							<li>Reduce costs associated with hiring human chatters</li>
						</ul>
						<br />
						They also help maintain consistent interaction levels, which is{" "}
						<b>crucial</b> for fan retention. <br />
						<br />
						Additionally, <i>AI-powered chatters for OnlyFans</i> can analyze
						interaction data to optimize future conversations and improve
						overall fan engagement.
					</span>
				),
			},
			{
				question: "Can AI chatters truly mimic the creator's personality?",
				answer: (
					<span>
						Yes, <b>AI chatters</b> can be programmed to reflect a
						creator&apos;s unique style and personality by learning from past
						interactions.
						<br />
						<br />
						They can generate responses that feel authentic to the fans, making
						the experience seem personal. <i>AI chatters for OnlyFans</i> are
						designed to adapt to the creator’s tone, ensuring a seamless and
						authentic fan experience.
					</span>
				),
			},
			{
				question: "What are the benefits of using AI chatters on OnlyFans?",
				icon: "Sparkles",
				iconColor: "gold",
				answer: (
					<span>
						<b>Benefits</b> include:
						<ul className="list-disc list-inside">
							<li>Around-the-clock availability</li>
							<li>Personalized fan engagement</li>
							<li>The ability to manage large volumes of interactions</li>
							<li>
								Use new types of content such as voice notes, AI generated
								images and videos that couldn&apos;t be created by human
								chatters
							</li>
							<li>
								Analysing interaction data to improve future communication
								strategies
							</li>
						</ul>
						<br />
						<i>AI-driven chat systems</i> for OnlyFans also help creators scale
						their engagement efforts without compromising on the quality of
						interactions.
					</span>
				),
			},
			{
				question: "Are there risks to using AI chatters?",
				answer: (
					<span>
						Very low, AI chatters are designed to be as authentic as possible.
						And we use Telegram to further reduce risk and protect your OF
						account.
						<br />
						<br />
						At OFA, we ensure that your AI chatters are specifically designed
						for your tone and style to minimize these risks.
						<br />
						<br />
						We also constantly tested our AI chatters, to ensure the{" "}
						<b>highest level of quality.</b>
					</span>
				),
			},
		],
	},
	{
		section: "Safety",
		qa: [
			{
				question:
					"How does OFA (OnlyFans Agent) protect my account when using AI chatters?",

				answer: (
					<span>
						Firstly, we move users from OF to Telegram (where everything is
						encrypted and removes platform risk, ensuring you OF account is
						untouched.)
						<br />
						<br />
						Secondly, We safety check all interactions.
						<br />
						<br />
						Using <b>AI chatters for OnlyFans</b> through a trusted agency like
						OFA helps ensure that all fan interactions are secure and compliant
						with all platform rules.
					</span>
				),
			},
			{
				question: "Is it safe to take fan interactions to Telegram?",
				icon: "Shield",
				iconColor: "green",
				answer: (
					<span>
						Moving fan interactions to Telegram can be safer.
						<br />
						<br />
						Telegram offers:
						<ul className="list-disc list-inside">
							<li>
								<b>end-to-end encryption</b>
							</li>
							<li>
								<b>more flexibility</b>
							</li>
							<li>
								<b>more features</b>
							</li>
						</ul>
						to better engage with your fans
					</span>
				),
			},
			{
				question: "Why would creators take conversations to Telegram?",
				answer: (
					<span>
						Telegram is often used for higher levels of privacy and to manage
						more personal or explicit interactions.
						<br />
						<br />
						It also allows creators to offer exclusive content or services
						outside the platform&apos;s constraints. Creators who use{" "}
						<i>AI chat systems</i> on OnlyFans may transition to Telegram for
						more intimate fan interactions, leveraging the privacy features of
						the platform.
					</span>
				),
			},
			{
				question: "How do you ensure privacy when using Telegram?",
				answer: (
					<span>
						Telegram is known for its privacy.
						<br />
						<br />
						On Telegram, everything is <b>end-to-end encrypted</b> by default.
					</span>
				),
			},
			{
				question:
					"What measures are in place to prevent unauthorized access to my OnlyFans or Telegram accounts?",
				answer: <span>We never access your accounts, only you can.</span>,
			},
		],
	},
]

export const FAQs = () => {
	return (
		<section className="p-8" id="faq">
			<div className="mx-auto">
				<SectionHeader
					subheader="FAQs"
					title="Frequently Asked Questions"
					description="Curious about how AI is transforming fan interactions on OnlyFans? Here are some of the most common questions we get."
				/>

				<div className="grid grid-cols-1 gap-8 mx-auto my-12 md:grid-cols-2">
					{faqs.map((section, sectionIdx) => (
						<section
							key={sectionIdx}
							id={"faq-" + section.section}
							className="w-full"
						>
							<p className="mb-4 text-left H3 text-stone-light">
								{section.section}
							</p>
							<div className="p-4 w-full rounded-lg">
								<div className="gi">
									{section.qa.map((faq, index) => (
										<Question
											key={index}
											question={faq.question}
											answer={faq.answer}
											icon={faq.icon as keyof typeof LucideIcons | undefined}
											iconColor={faq.iconColor}
										/>
									))}
								</div>
							</div>
						</section>
					))}
				</div>
			</div>
			<h4 className="mb-12 text-sm font-medium tracking-tight text-center text-foreground/80">
				Still have questions? Email us at{" "}
				<a href="mailto:hello@ofa.app" className="underline">
					hello@ofa.app
				</a>
			</h4>
		</section>
	)
}
