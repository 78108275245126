import React from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { StepItem, Steps } from "src/components/ui/Steps"

import { AccountScreen } from "./account.screen"
import { PersonaScreen } from "./persona.screen"
import { ReviewScreen } from "./review.screen"
import { ScenarioScreen } from "./scenario.screen"

const steps: StepItem[] = [
	{ step: 1, label: "Account type", path: "/onboarding/start" },
	{ step: 2, label: "Create Persona", path: "/onboarding/persona" },
	{ step: 3, label: "Create Scenario", path: "/onboarding/scenario" },
	{ step: 4, label: "Review and Complete", path: "/onboarding/review" },
]

export const OnboardingRoutes: React.FC = () => {
	const { pathname } = useLocation()
	const currentStepIndex = steps.findIndex(step => step.path === pathname)

	return (
		<section>
			<Steps steps={steps} currentStep={currentStepIndex} />
			<div className="p-2 mx-auto max-w-6xl min-h-screen">
				<Routes>
					<Route path="start" element={<AccountScreen />} />
					<Route path="persona" element={<PersonaScreen />} />
					<Route path="scenario" element={<ScenarioScreen />} />
					<Route path="review" element={<ReviewScreen />} />
				</Routes>
			</div>
		</section>
	)
}
