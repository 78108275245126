import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { Loader2 } from "lucide-react"

import { cn } from "../../lib/utils"

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-full ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 font-sans bold",
	{
		variants: {
			variant: {
				default: "bg-stone-dark text-stone-pale hover:bg-stone-dark/90",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary: "bg-stone-pale text-stone-mid hover:bg-stone-light",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
				sm: "h-8 px-4",
				lg: "h-12 px-6",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
	is_loading?: boolean
}

const SubmitButton: React.FC<ButtonProps> = ({
	className,
	variant,
	size,
	asChild = false,
	is_loading = false,
	children,
	...props
}) => {
	const Comp = asChild ? Slot : "button"
	return (
		<Comp
			className={cn(buttonVariants({ variant, size, className }))}
			disabled={is_loading}
			{...props}
		>
			{is_loading && <Loader2 className="mr-2 w-4 h-4 animate-spin" />}
			{children}
		</Comp>
	)
}

export { buttonVariants, SubmitButton }
