import { ReactNode } from "react"
import { cn } from "src/lib/utils"

export default function AnimatedGradientText({
	children,
	className,
}: {
	children: ReactNode
	className?: string
}) {
	return (
		<div
			className={cn(
				"group relative flex max-w-fit items-center justify-center duration-500 ease-out [--bg-size:300%]",
				className
			)}
		>
			{children}
		</div>
	)
}
