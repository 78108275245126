import React, { useEffect } from "react"
import {
	STRIPE_PRICING_TABLE_ID,
	STRIPE_PUBLISHABLE_KEY,
} from "src/lib/constants"

import { Card } from "../ui/Card"

import { SectionHeader } from "./SectionHeader"

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"stripe-pricing-table": React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLElement>,
				HTMLElement
			>
		}
	}
}

export const Pricing = () => {
	useEffect(() => {
		const script = document.createElement("script")
		script.src = "https://js.stripe.com/v3/pricing-table.js"
		script.async = true
		document.body.appendChild(script)
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<section id="pricing">
			<Card className="overflow-hidden">
				<SectionHeader
					subheader="Pricing plans"
					title="Simple pricing for everyone"
					description="Choose an affordable plan that's packed with the best features for engaging your audience, creating customer loyalty, and driving sales."
				/>
				<stripe-pricing-table
					pricing-table-id={STRIPE_PRICING_TABLE_ID}
					publishable-key={STRIPE_PUBLISHABLE_KEY}
				/>
			</Card>
		</section>
	)
}
