import {
	BarChart,
	DollarSign,
	ShoppingCart,
	Users,
	Variable,
} from "lucide-react"

export const statsData = [
	{
		title: "Total Revenue",
		value: "$85,231.89",
		change: "+20.1%",
		icon: DollarSign,
	},
	{
		title: "Avg. Basket Size",
		value: "$47.50",
		change: "+5.2%",
		icon: ShoppingCart,
	},
	{
		title: "Conversion Rate",
		value: "11.2%",
		change: "+0.8%",
		icon: BarChart,
	},
	{
		title: "Active Subscribers",
		value: "8,682",
		change: "+21 since last week",
		icon: Users,
	},
	{
		title: "Life time value",
		value: "$834",
		change: "Average 6.5 months",
		icon: Variable,
	},
]

export const revenueMetricsData = [
	{ date: "2023-05-01", daily: 420, trailing30: 11200 },
	{ date: "2023-05-02", daily: 550, trailing30: 11750 },
	{ date: "2023-05-03", daily: 630, trailing30: 12380 },
	{ date: "2023-05-04", daily: 580, trailing30: 12960 },
	{ date: "2023-05-05", daily: 700, trailing30: 13660 },
]

export const popularContentData = [
	{ category: "Anal", revenue: 5200, fill: "#005EFF" },
	{ category: "Blowjob", revenue: 4800, fill: "#FF6154" },
	{ category: "Creampie", revenue: 4500, fill: "#C3FF61" },
	{ category: "Cum Shot", revenue: 3800, fill: "#FFD700" },
	{ category: "Small Talk", revenue: 3200, fill: "#6F00FF" },
]

export const topCustomersData = [
	{
		customer: "John Doe",
		email: "john@example.com",
		totalOrders: 25,
		lastOrder: "2 days ago",
		totalSpent: 2250.5,
	},
	{
		customer: "Jane Smith",
		email: "jane@example.com",
		totalOrders: 30,
		lastOrder: "1 day ago",
		totalSpent: 2800.75,
	},
	{
		customer: "Mike Johnson",
		email: "mike@example.com",
		totalOrders: 22,
		lastOrder: "3 days ago",
		totalSpent: 1950.25,
	},
	{
		customer: "Emily Brown",
		email: "emily@example.com",
		totalOrders: 28,
		lastOrder: "4 days ago",
		totalSpent: 2600.0,
	},
	{
		customer: "David Wilson",
		email: "david@example.com",
		totalOrders: 20,
		lastOrder: "5 days ago",
		totalSpent: 1800.5,
	},
	{
		customer: "Sarah Taylor",
		email: "sarah@example.com",
		totalOrders: 35,
		lastOrder: "1 day ago",
		totalSpent: 3100.25,
	},
]

export const keywordsData = [
	{ keyword: "too expensive", count: 145 },
	{ keyword: "not interested", count: 130 },
	{ keyword: "already subscribed", count: 125 },
	{ keyword: "prefer free content", count: 110 },
	{ keyword: "saving money", count: 95 },
]

export const contentFunnelData = [
	{ month: "January", traffic: 2186, conversion: 1580 },
	{ month: "February", traffic: 2400, conversion: 1790 },
	{ month: "March", traffic: 2620, conversion: 2200 },
	{ month: "April", traffic: 2850, conversion: 2450 },
	{ month: "May", traffic: 3100, conversion: 2800 },
]

export const monthlyEarningsData = [
	{ month: "January", current: 8200, past: 7500 },
	{ month: "February", current: 9500, past: 8800 },
	{ month: "March", current: 9000, past: 8200 },
	{ month: "April", current: 11800, past: 10500 },
	{ month: "May", current: 13200, past: 11800 },
	{ month: "June", current: 14500, past: 13000 },
]

export const recentSalesData = [
	{
		name: "Alice",
		email: "alice@example.com",
		amount: "$300",
		content: "Lingerie Set",
	},
	{
		name: "Bob",
		email: "bob@example.com",
		amount: "$450",
		content: "Cosplay Video",
	},
	{
		name: "Charlie",
		email: "charlie@example.com",
		amount: "$250",
		content: "Shower Photoshoot",
	},

	{
		name: "Henry",
		email: "henry@example.com",
		amount: "$200",
		content: "Behind the Scenes",
	},
	{
		name: "Ivy",
		email: "ivy@example.com",
		amount: "$600",
		content: "Private Livestream",
	},
	{
		name: "Jack",
		email: "jack@example.com",
		amount: "$150",
		content: "Signed Poster",
	},
]

export const bestDaysData = [
	{ name: "Mon", value: 620 },
	{ name: "Tue", value: 750 },
	{ name: "Wed", value: 830 },
	{ name: "Thu", value: 780 },
	{ name: "Fri", value: 900 },
	{ name: "Sat", value: 1100 },
	{ name: "Sun", value: 950 },
]

export const bestHoursData = [
	{ name: "12am", value: 120 },
	{ name: "4am", value: 85 },
	{ name: "8am", value: 130 },
	{ name: "12pm", value: 220 },
	{ name: "4pm", value: 280 },
	{ name: "8pm", value: 330 },
]

export const unmatchedDesiresData = [
	"Feet",
	"ASMR",
	"Cooking",
	"Gaming",
	"Workout",
]

export const fanCategorizationData = {
	revenueData: [
		{ category: "High ", value: 5000 },
		{ category: "Mid ", value: 3000 },
		{ category: "Low ", value: 2000 },
	],
	desireData: [
		{ category: "Anal", value: 3200 },
		{ category: "Blowjob", value: 2800 },
		{ category: "Creampie", value: 2500 },
		{ category: "Cum Shot", value: 2200 },
		{ category: "Small Talk", value: 1800 },
		{ category: "Feet", value: 1500 },
		{ category: "ASMR", value: 1200 },
		{ category: "Cooking", value: 900 },
	],
	locationData: [
		{ category: "North America", value: 4500 },
		{ category: "Europe", value: 3800 },
		{ category: "Asia", value: 3200 },
		{ category: "South America", value: 2500 },
		{ category: "Africa", value: 1800 },
		{ category: "Oceania", value: 1500 },
	],
	languageData: [
		{ category: "English", value: 5000 },
		{ category: "Spanish", value: 3500 },
		{ category: "French", value: 2800 },
		{ category: "German", value: 2500 },
		{ category: "Japanese", value: 2200 },
		{ category: "Chinese", value: 2000 },
	],
	ageRangeData: [
		{ category: "18-24", value: 3500 },
		{ category: "25-34", value: 4500 },
		{ category: "35-44", value: 3800 },
		{ category: "45-54", value: 2500 },
		{ category: "55+", value: 1500 },
	],
}
