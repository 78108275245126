import { SectionHeader } from "src/components/blocks/SectionHeader"

import { BentoCard, BentoGrid } from "./Bento"
import { Grow } from "./why-cards/Grow"
import { Hiring } from "./why-cards/Hiring"
import { KnowYourCustomer } from "./why-cards/KnowYourCustomer"
import { Notifications } from "./why-cards/Notifications"
import { Personal } from "./why-cards/Personal"
import { Streams } from "./why-cards/Streams"

const features = [
	{
		name: "Available 24/7",
		description: (
			<span>
				Offer clients <strong>seamless availability</strong>, ensuring fans
				receive <strong>immediate responses</strong> across different time
				zones.
			</span>
		),

		background: <Notifications />,
		className: "lg:row-start-1 lg:row-end-4 lg:col-start-2 lg:col-end-3",
	},
	{
		name: "Personalized",
		description: (
			<span>
				Utilize AI to create <strong>bespoke and targeted messages</strong> to
				each user&apos;s needs, making them feel <strong>valued</strong> and{" "}
				<strong>unique</strong>.
			</span>
		),

		background: <Personal />,
		className: "lg:col-start-3 lg:col-end-3 lg:row-start-2 lg:row-end-4",
	},

	{
		name: "Create a New Revenue Stream",
		description: (
			<span>
				Unlock new revenue opportunities with{" "}
				<strong>innovative monetization strategies</strong> designed to align
				with <strong>your audience&apos;s unique preferences</strong>.
			</span>
		),

		background: <Streams />,
		className: "lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3",
	},
	{
		name: "Scale Engagement",
		description: (
			<span>
				Address the limitations of human capacity, allowing{" "}
				<strong>modular scalability</strong> to maintain{" "}
				<strong>high-quality interactions</strong> as your fanbase grows.
			</span>
		),

		background: <Grow />,
		className: "lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4",
	},
	{
		name: "Know Your Customer",
		description: (
			<span>
				Utilize chat data to <strong>understand fan preferences</strong>, which
				can inform <strong>content creation</strong> and{" "}
				<strong>marketing strategies</strong>.
			</span>
		),

		background: <KnowYourCustomer />,
		className: "lg:col-start-3 lg:col-end-3 lg:row-start-1 lg:row-end-2",
	},
]

export const Why = () => {
	return (
		<section id="features">
			<SectionHeader
				subheader="Why?"
				title="The Benefits of AI-Based Chatter for Agencies"
				description="Discover how AI-driven interactions on OnlyFans can revolutionize engagement and operational efficiency for agencies."
			/>
			<BentoGrid className="px-2 pb-2 pt-2 md:max-h-[888px] md:px-8 md:pb-2 md:pt-8 lg:grid-rows-3">
				{features.map(feature => (
					<BentoCard key={feature.name} {...feature} />
				))}
			</BentoGrid>
			<div className="flex justify-center px-2 md:px-8">
				<Hiring />
			</div>
		</section>
	)
}
