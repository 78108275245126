import React, { useEffect, useState } from "react"
// Add this import
import { useNavigate } from "react-router-dom"
import trpc_client from "src/lib/trpc"

import { PublicSafePersona } from "../../../../../api/src/models/persona/persona.schema"
import {
	BestDays,
	BestHours,
	ContentFunnel,
	FanCategorization,
	Keywords,
	MonthlyEarnings,
	PopularContent,
	RecentSales,
	RevenueMetrics,
	Stats,
	TopCustomersTable,
	UnmatchedDesires,
} from "../../../components/organisms/Dashboard"
import {
	bestDaysData,
	bestHoursData,
	contentFunnelData,
	fanCategorizationData,
	keywordsData,
	monthlyEarningsData,
	popularContentData,
	recentSalesData,
	revenueMetricsData,
	statsData,
	topCustomersData,
	unmatchedDesiresData,
} from "../../../components/organisms/Persona/mockData"
import { Button } from "../../../components/ui/Button"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../../components/ui/Card"

export const DashboardScreen: React.FC = () => {
	const [personas, setPersonas] = useState<PublicSafePersona[]>([])
	const navigate = useNavigate()

	useEffect(() => {
		const fetchPersonas = async () => {
			const fetchedPersonas = await trpc_client.persona.listPersonas.query()
			setPersonas(fetchedPersonas)
		}
		fetchPersonas()
	}, [])

	const handleCreatePersona = () => {
		navigate("/onboarding/start")
	}

	return (
		<section className="flex flex-col w-full min-h-screen">
			<CardHeader>
				<CardTitle>Welcome to Your Dashboard</CardTitle>
				<CardDescription>
					This is your analytics for all your personas.
				</CardDescription>
			</CardHeader>
			<div className="flex flex-col flex-1 gap-4 p-6">
				{personas.length === 0 && (
					<WelcomeCard onCreatePersona={handleCreatePersona} />
				)}

				<Stats data={statsData} />

				<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
					<MonthlyEarnings data={monthlyEarningsData} />
					<PopularContent data={popularContentData} />
				</div>

				<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
					<TopCustomersTable top_customers={topCustomersData} />
					<RecentSales recentSales={recentSalesData} />
					<Keywords data={keywordsData} />
					<ContentFunnel data={contentFunnelData} />
					<RevenueMetrics data={revenueMetricsData} />
				</div>

				<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
					<BestDays data={bestDaysData} />
					<BestHours data={bestHoursData} />
					<UnmatchedDesires data={unmatchedDesiresData} />
				</div>

				<FanCategorization
					revenueData={fanCategorizationData.revenueData}
					desireData={fanCategorizationData.desireData}
					locationData={fanCategorizationData.locationData}
					languageData={fanCategorizationData.languageData}
					ageRangeData={fanCategorizationData.ageRangeData}
				/>
			</div>
		</section>
	)
}

const WelcomeCard: React.FC<{ onCreatePersona: () => void }> = ({
	onCreatePersona,
}) => (
	<Card>
		<CardHeader>
			<CardTitle>Welcome to Your Dashboard</CardTitle>
			<CardDescription>
				Get started by creating your first persona
			</CardDescription>
		</CardHeader>
		<CardContent>
			<Button variant="outline" onClick={onCreatePersona}>
				Create Persona
			</Button>
		</CardContent>
	</Card>
)
