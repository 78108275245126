import React, { ChangeEvent, useRef, useState } from "react"
import { FileUp, Loader2 } from "lucide-react"
import { S3Response } from "react-s3-uploader"
import trpc_client from "src/lib/trpc"

export interface UploaderConfig {
	maxFileSize?: number
	allowedFileTypes?: string[]
}

interface BulkUploaderProps {
	persona_id: string
	onSuccess: (result: S3Response) => void
	onError: (error: Error) => void
	config: UploaderConfig
}
const BulkUploader: React.FC<BulkUploaderProps> = ({
	persona_id,
	onSuccess,
	onError,
	config,
}) => {
	const [uploading, setUploading] = useState<boolean>(false)

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = Array.from(event.target.files || [])
		setUploading(true)

		for (const file of selectedFiles) {
			if (config.maxFileSize && file.size > config.maxFileSize) {
				onError(
					new Error(
						`File ${file.name} exceeds the maximum size of ${config.maxFileSize} bytes`
					)
				)
				continue
			}
			if (
				config.allowedFileTypes &&
				!config.allowedFileTypes.includes(file.type)
			) {
				onError(
					new Error(
						`File ${
							file.name
						} is not an allowed file type. Allowed types: ${config.allowedFileTypes.join(
							", "
						)}`
					)
				)
				continue
			}

			try {
				const contentType = file.type || "application/octet-stream"

				const response = await trpc_client.content.getSignedWriteUrl.query({
					content_type: file.type,
					file_name: file.name,
					persona_id: persona_id,
				})

				const uploadResponse = await fetch(response.signedUrl, {
					method: "PUT",
					headers: {
						"Content-Type": contentType,
					},
					body: file,
				})

				if (!uploadResponse.ok) {
					throw new Error(`Failed to upload file ${file.name}`)
				}

				console.log(`File uploaded successfully: ${file.name}`)
				onSuccess({
					filename: file.name,
					fileKey: response.fileKey,
					publicUrl: response.publicUrl,
					signedUrl: response.signedUrl,
				})
			} catch (error) {
				console.error(`Error uploading file ${file.name}:`, error)
				onError(error as Error)
			}
		}

		setUploading(false)
		event.target.value = "" // Reset the file input
	}

	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleDivClick = () => {
		fileInputRef.current?.click()
	}

	return (
		<div
			className="flex flex-col justify-center items-center p-8 text-center rounded-2xl cursor-pointer hover:bg-stone-pale/70 bg-stone-pale"
			onClick={handleDivClick}
		>
			<input
				type="file"
				multiple
				onChange={handleFileChange}
				ref={fileInputRef}
				className="hidden"
				disabled={uploading}
			/>
			{uploading ? (
				<Loader2 className="mb-2 w-8 h-8 animate-spin text-stone-light" />
			) : (
				<FileUp className="mb-2 w-8 h-8 text-stone-light" />
			)}
			<p className="text-stone-light">
				{uploading ? "Uploading..." : "Click here to upload files"}
			</p>
		</div>
	)
}

export default BulkUploader
