import { Pricing } from "src/components/blocks/Pricing"

import { FAQs } from "../components/blocks/FAQs"
import { FinalCTA } from "../components/blocks/FinalCTA"
import { Hero } from "../components/blocks/Hero"
import { How } from "../components/blocks/How"
import { Testimonials } from "../components/blocks/Testimonials"
import { Trust } from "../components/blocks/Trust"
import { Why } from "../components/blocks/Why"

export default function Home() {
	return (
		<main>
			<Hero />
			<Trust />
			<Why />
			<How />
			<Testimonials />
			<Pricing />
			<FAQs />
			<FinalCTA />
		</main>
	)
}
