import AnimatedGradientText from "src/components/effects/AnimatedGradientText"
import DotPattern from "src/components/effects/DottedPattern"
import { cn } from "src/lib/utils"

import { CTA } from "../ui/CTA"

export const FinalCTA = () => {
	return (
		<section className="flex overflow-hidden relative flex-col justify-center items-center p-2 min-h-svh md:p-8">
			<div className="flex overflow-hidden flex-col flex-1 gap-4 justify-center items-center w-full bg-white rounded-2xl">
				<AnimatedGradientText>
					<p
						className={cn(
							`H0 inline animate-gradient text-balance bg-gradient-to-r from-[#a9ff40] via-[#40fff9] to-[#a9ff40] bg-[length:var(--bg-size)_100%] bg-clip-text text-center tracking-tighter text-transparent`
						)}
						style={{
							transition: "opacity 0.6s ease 0.1s, transform 0.6s ease 0.1s",
						}}
					>
						Always available.
						<br />
						Infinitely scaleable.
						<br />
						Uniquely yours.
					</p>
				</AnimatedGradientText>

				<p className="mb-8 w-full text-center subheader text-cream-mid">
					AI Chatter Agents that engage and convert.
				</p>
				<CTA />

				<DotPattern
					width={20}
					height={20}
					cx={1}
					cy={1}
					cr={1}
					className={cn(
						"[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
					)}
				/>
			</div>
		</section>
	)
}
