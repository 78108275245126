import { useEffect, useState } from "react"
import { S3Response } from "react-s3-uploader"
import { toast } from "sonner"
import trpc_client from "../lib/trpc"
import generateUUIDLikeHash from "../lib/uuid_like_hash"

import { ContentItem } from "../components/organisms/ContentTable/ContentRow/useUpdateContent"

export const useContentTable = (content_set_id: string, persona_id: string) => {
	const [content, setContent] = useState<ContentItem[]>([])
	const [is_loading, setIsLoading] = useState(true)

	useEffect(() => {
		const fetchContent = async () => {
			setIsLoading(true)
			try {
				const { contents } = await trpc_client.content.set.get.query({
					id: content_set_id,
				})

				const mapped_content: ContentItem[] = contents.map(item => ({
					id: item.id,
					title: item.title,
					description: item.description,
					key: item.key,
					content_type: item.content_type,
					price: item.price,
					tags: item.metadata.tags || [],
					explicit_level: item.explicit_level,
					read_url: item.read_url,
					new_content: false,
				}))

				setContent(mapped_content)
			} catch (error) {
				console.error(error)
			} finally {
				setIsLoading(false)
			}
		}

		fetchContent()
	}, [content_set_id, persona_id])

	const onDelete = (content_id: string) => {
		setContent(prev => prev.filter(item => item.id !== content_id))
	}

	const onFileUploaded = async (file: S3Response) => {
		const makeContent = async (file: S3Response): Promise<ContentItem> => {
			const getContentType = (filename: string): string => {
				const extension = filename.split(".").pop()?.toLowerCase()
				const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"]
				const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "webm"]

				if (imageExtensions.includes(extension || "")) {
					return "image"
				} else if (videoExtensions.includes(extension || "")) {
					return "video"
				} else {
					return "unknown"
				}
			}

			const content_type = getContentType(file.filename)
			const id = await generateUUIDLikeHash(`${persona_id}:${file.fileKey}`)

			return {
				id,
				title: file.filename || "",
				description: "",
				key: file.fileKey,
				content_type,
				price: 0,
				tags: [],
				explicit_level: "",
				read_url: file.publicUrl,
				new_content: true,
			}
		}

		const new_content = await makeContent(file)
		setContent(prev => [new_content, ...prev])

		toast.success("File uploaded successfully")
	}

	const onError = (error: Error) => {
		console.error(error)
	}

	return {
		content,
		is_loading,
		onDelete,
		onFileUploaded,
		onError,
	}
}
