import React from "react"
import { motion } from "framer-motion"

interface LoadingIndicatorProps {
	state: LoadingState
	tooltip: string
}

export enum LoadingState {
	SUCCESS,
	ERROR,
	LOADING,
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	state,
	tooltip,
}) => {
	const variants = {
		initial: { scale: 0, opacity: 0 },
		animate: { scale: 1, opacity: 1 },
		exit: { scale: 0, opacity: 0 },
	}

	return (
		<motion.div
			initial="initial"
			animate="animate"
			exit="exit"
			className="ml-4"
			variants={variants}
			transition={{ duration: 0.3 }}
			title={tooltip}
		>
			{(() => {
				switch (state) {
					case LoadingState.SUCCESS:
						return <div className="w-3 h-3 rounded-full bg-green-mid" />
					case LoadingState.LOADING:
						return <div className="w-3 h-3 rounded-full bg-yellow-root" />
					case LoadingState.ERROR:
						return <div className="w-3 h-3 rounded-full bg-red-root" />
					default:
						return null
				}
			})()}
		</motion.div>
	)
}

export default LoadingIndicator
