const companies = ["Google", "OpenAI", "Meta"]

export const Trust = () => {
	return (
		<section id="Trust">
			<div className="py-14">
				<div className="container mx-auto px-4 md:px-8">
					<p className="text-center text-stone-light">
						BUILT USING ENTERPRISE GRADE TECHNOLOGIES
					</p>
					<div className="relative mt-6">
						<div className="grid grid-cols-3 place-items-center gap-2 md:grid-cols-3 xl:grid-cols-3 xl:gap-2">
							{companies.map((logo, idx) => (
								<img
									key={idx}
									src={`/images/${logo.toLowerCase()}_logo.svg.svg`}
									className="h-16 w-80 px-2 dark:brightness-0 dark:invert"
									alt={logo}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
