import { cubicBezier, motion } from "framer-motion"

export const CarbonCopy = () => {
	const variant1 = {
		initial: {
			y: 0,
			scale: 0.95,
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			y: 25,
			x: 10,
			scale: 1,
			rotate: -2,
			boxShadow:
				"rgba(39,127,245,0.35) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}
	const variant2 = {
		initial: {
			y: 0,
			scale: 0.95,
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
		whileHover: {
			y: -25,
			x: -10,
			scale: 1,
			rotate: 3,
			boxShadow:
				"rgba(39,245,76,0.35) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
			transition: {
				delay: 0,
				duration: 0.2,
				ease: cubicBezier(0.22, 1, 0.36, 1),
			},
		},
	}

	const containerVariants = {
		initial: {},
		whileHover: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	}

	return (
		<div className="relative h-full w-full transform-gpu rounded-2xl bg-white md:max-h-[500px]">
			<motion.div
				variants={containerVariants}
				initial="initial"
				whileHover="whileHover"
				className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
			>
				<div className="flex h-[300px] w-full items-center justify-center rounded-t-xl bg-transparent">
					<div className="relative flex cursor-pointer flex-col items-center justify-center gap-y-2 p-14">
						<motion.div
							variants={variant1}
							className="dark:bg-stone-980 z-[3] flex h-full w-full items-center justify-between gap-x-2 rounded-md border border-stone-400/20 bg-white p-5 px-2.5 transition-all duration-100 ease-linear dark:border-stone-800 dark:bg-stone-900"
						>
							<div className="h-8 w-8 rounded-full bg-pink-300">
								<img
									className="h-full w-full rounded-full object-cover"
									src="https://avatar.iran.liara.run/public/81"
									alt="sammy"
								/>
							</div>
							<div className="flex flex-col gap-y-2">
								<div className="h-2 w-32 rounded-full bg-stone-800/50 dark:bg-stone-200/80"></div>
								<div className="h-2 w-48 rounded-full bg-slate-400/50"></div>
								<div className="h-2 w-20 rounded-full bg-slate-400/50"></div>
								<div className="h-2 w-48 rounded-full bg-slate-400/50"></div>
							</div>
						</motion.div>
						<motion.div
							variants={variant2}
							className="absolute bottom-14 z-[2] m-auto flex h-fit w-fit items-center justify-between gap-x-2 rounded-md border border-stone-400/20 bg-white p-5 px-2.5 transition-all duration-100 ease-linear dark:border-stone-800 dark:bg-stone-900"
						>
							<div className="h-8 w-8 rounded-full bg-pink-300">
								<img
									className="h-full w-full rounded-full object-cover"
									src="https://avatar.iran.liara.run/public/81"
									alt="sammy"
								/>
							</div>
							<div className="flex flex-col gap-y-2">
								<div className="h-2 w-32 rounded-full bg-stone-800/50 dark:bg-stone-200/80"></div>
								<div className="h-2 w-48 rounded-full bg-slate-400/50"></div>
								<div className="h-2 w-20 rounded-full bg-slate-400/50"></div>
								<div className="h-2 w-48 rounded-full bg-slate-400/50"></div>
							</div>
						</motion.div>
					</div>
				</div>
				<div className="flex w-full flex-col items-start p-4">
					<h3>1. Create Carbon Copy</h3>
					<p className="text-stone-light">
						We create a custom persona for each model
					</p>
				</div>
			</motion.div>
		</div>
	)
}
