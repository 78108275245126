export interface Question {
	question: string
	answer: string
	type?: "input" | "textarea" | "select"
	options?: string[]
	condition?: { field: string; value: string }
}

export interface QuestionGroup {
	title: string
	questions: (Question | Question[])[]
}

export const initialQuestions: QuestionGroup[] = [
	{
		title: "Personal Information",
		questions: [
			[
				{ question: "Name", answer: "" },
				{ question: "Age", answer: "" },
			],
			{
				question: "Gender",
				answer: "",
				type: "select",
				options: ["Male", "Female"],
			},
			[
				{ question: "Birthday", answer: "" },
				{ question: "Height", answer: "" },
			],
			[
				{ question: "Home Town", answer: "" },
				{ question: "Current Location", answer: "" },
			],
			[
				{ question: "Education", answer: "" },
				{ question: "Profession/Job", answer: "" },
			],
			[
				{ question: "Language", answer: "" },
				{ question: "Relationship Status", answer: "" },
			],
			{
				question: "Cock Size",
				answer: "",
				condition: { field: "Gender", value: "Male" },
			},
			{
				question: "Boob Size",
				answer: "",
				condition: { field: "Gender", value: "Female" },
			},
			{
				question: "Waist Size",
				answer: "",
				condition: { field: "Gender", value: "Female" },
			},
			[
				{ question: "Shoe Size", answer: "" },
				{ question: "Model Niche", answer: "" },
			],
			{ question: "Tattoos", answer: "" },
			{ question: "Hobbies", answer: "" },
		],
	},
	{
		title: "Lifestyle",
		questions: [
			{ question: "Who do you live with?", answer: "", type: "textarea" },
			{
				question: "What are your favourite restaurants to go to?",
				answer: "",
				type: "textarea",
			},
			{
				question: "Do you often go on nights out? If so, where do you go?",
				answer: "",
				type: "textarea",
			},
			{
				question: "Do you have any upcoming holiday plans?",
				answer: "",
				type: "textarea",
			},
			{
				question: "What is your idea of a perfect date?",
				answer: "",
				type: "textarea",
			},
			{
				question: "Where is the best place that you have visited?",
				answer: "",
				type: "textarea",
			},
		],
	},
	{
		title: "Favorites",
		questions: [
			{ question: "Music", answer: "", type: "textarea" },
			{ question: "TV/Film", answer: "", type: "textarea" },
			{ question: "Food", answer: "", type: "textarea" },
			{ question: "Color", answer: "", type: "textarea" },
			{ question: "Sports", answer: "", type: "textarea" },
		],
	},
	{
		title: "Future Plans",
		questions: [{ question: "Future Plans", answer: "", type: "textarea" }],
	},
]
