"use client"

import React from "react"
import { TrendingUp } from "lucide-react"
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/Chart"

interface RevenueMetricsProps {
	data: Array<{ date: string; daily: number; trailing30: number }>
}

const chartConfig: ChartConfig = {
	daily: {
		label: "Daily Revenue",
		color: "#FF6154",
	},
	trailing30: {
		label: "Trailing 30 Days",
		color: "#99C74E",
	},
}

export const RevenueMetrics: React.FC<RevenueMetricsProps> = ({ data }) => {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Revenue Metrics</CardTitle>
				<CardDescription>Daily and Trailing 30 Days Revenue</CardDescription>
			</CardHeader>
			<CardContent>
				<ChartContainer config={chartConfig}>
					<BarChart data={data}>
						<CartesianGrid vertical={false} />
						<XAxis
							dataKey="date"
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							tickFormatter={value => value.slice(5)}
						/>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent indicator="dashed" />}
						/>
						<Bar dataKey="daily" fill="var(--color-daily)" radius={4} />
						<Bar
							dataKey="trailing30"
							fill="var(--color-trailing30)"
							radius={4}
						/>
					</BarChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 items-start text-sm">
				<div className="flex gap-2 font-medium leading-none">
					Trending up by 5.2% this month <TrendingUp className="w-4 h-4" />
				</div>
				<div className="leading-none text-muted-foreground">
					Showing daily and trailing 30 days revenue
				</div>
			</CardFooter>
		</Card>
	)
}
