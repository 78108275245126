import { useCallback, useEffect, useMemo, useState } from "react"
import trpc_client from "src/lib/trpc"
import { useDebouncedCallback } from "use-debounce"

import { LoadingState } from "./LoadingIndicator"

export interface ContentItem {
	id: string
	title: string
	description: string
	price: number
	content_type: string
	explicit_level: string
	tags: string[]
	key: string
	read_url: string // url that can be used to read the content
	new_content: boolean
}

export interface ContentItemErrors {
	title?: string
	description?: string
	price?: string
	explicit_level?: string
	tags?: string
	delete?: string
}

const key_descriptions = {
	title: "title",
	description: "description",
	price: "price",
	explicit_level: "explicit Level",
	tags: "tags",
	key: "content",
}

const DEBOUNCE_TIME = 1000 // 1s debounce time, adjust as needed

const useUpdateContent = (
	content_set_id: string,
	initial_content: ContentItem,
	onDelete: (content_id: string) => void
): {
	content: ContentItem
	handleUpdateContent: (key: string, value: any) => Promise<void>
	deleteContent: () => Promise<void>
	is_updating: boolean
	is_deleting: boolean
	update_error: boolean
	errors: ContentItemErrors
	update_info: string
	loading_state: LoadingState
} => {
	const [content, setContent] = useState<ContentItem>(initial_content)
	const [updating_keys, setUpdatingKeys] = useState<string[]>([])
	const [errors, setErrors] = useState<ContentItemErrors>({})
	const [update_error, setUpdateError] = useState<boolean>(false)
	const [is_deleting, setIsDeleting] = useState<boolean>(false)

	const loading_state = useMemo(() => {
		if (is_deleting) return LoadingState.LOADING
		if (updating_keys.length > 0) return LoadingState.LOADING
		if (update_error) return LoadingState.ERROR
		return LoadingState.SUCCESS
	}, [is_deleting, updating_keys, update_error])

	// This is to create new content items when an image is uploaded.
	useEffect(() => {
		if (initial_content.new_content) {
			const updateAllFields = async () => {
				const fieldsToUpdate = Object.keys(initial_content).filter(
					key => key !== "id" && key !== "new_content"
				)
				setUpdatingKeys(fieldsToUpdate)

				try {
					// TODO: Update the content trpc
					const new_content = await trpc_client.content.create.mutate({
						content_set_id,
						id: initial_content.id,
						data: fieldsToUpdate.reduce(
							(acc, key) => ({ ...acc, [key]: initial_content[key] }),
							{}
						),
					})

					setContent({
						...new_content,
						new_content: false,
						tags: new_content.metadata.tags || [], // Ensure tags are included
					})
					setUpdatingKeys([])
					setErrors({})
				} catch (error) {
					setUpdateError(true)
					setErrors(
						fieldsToUpdate.reduce(
							(acc, key) => ({ ...acc, [key]: "Failed to update" }),
							{}
						)
					)
					setUpdatingKeys([])
				}
			}

			updateAllFields()
		}
	}, [initial_content, content_set_id])

	const [update_info, setUpdateInfo] = useState<string>("")

	useEffect(() => {
		if (updating_keys.length > 0) {
			const updatingFields = updating_keys
				.map(key => key_descriptions[key] || key)
				.join(", ")
			setUpdateInfo(`Updating ${updatingFields}`)
		} else {
			setUpdateInfo("")
		}
	}, [updating_keys])

	const [is_updating, setIsUpdating] = useState(false)

	useEffect(() => {
		setIsUpdating(updating_keys.length > 0)
	}, [updating_keys])

	const handleUpdateContent = async (key: string, value: any) => {
		setContent(prev => ({ ...prev, [key]: value }))
		updateContent(key, value)
	}

	const updateContent = useDebouncedCallback(
		async (key: string, value: any) => {
			setUpdatingKeys(prev => [...prev, key])
			try {
				await trpc_client.content.update.mutate({
					content_set_id,
					id: content.id,
					data: {
						[key]: value,
					},
				})
				setErrors(prev => ({ ...prev, [key]: undefined }))
				setUpdateError(false)
			} catch (error) {
				setUpdateError(true)
				setErrors(prev => ({ ...prev, [key]: "Failed to update" }))
			} finally {
				setUpdatingKeys(prev => prev.filter(k => k !== key))
			}
		},
		DEBOUNCE_TIME
	)

	const deleteContent = useCallback(async () => {
		try {
			setIsDeleting(true)
			await trpc_client.content.delete.mutate({ id: content.id })
			onDelete(content.id)
		} catch (error) {
			setUpdateError(true)
			setErrors(prev => ({ ...prev, delete: "Failed to delete" }))
		} finally {
			setIsDeleting(false)
		}
	}, [content.id, onDelete])

	return {
		content,
		handleUpdateContent,
		deleteContent,
		is_updating,
		is_deleting,
		update_error,
		errors,
		update_info,
		loading_state,
	}
}

export default useUpdateContent
