import React from "react"
import { useEffect, useState } from "react"
import { MailCheck } from "lucide-react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { useAuth } from "src/providers/AuthProvider"

const VerifyEmail: React.FC = () => {
	const [isVerifying, setIsVerifying] = useState(false)
	const [searchParams] = useSearchParams()
	const { verify } = useAuth()
	const token = searchParams.get("token")
	const navigate = useNavigate()
	const redirect = searchParams.get("redirect")

	useEffect(() => {
		if (token) {
			setIsVerifying(true)
			verify(token)
				.then(() => {
					if (redirect === "/onboarding/start") {
						navigate(`/onboarding/start`)
					} else {
						navigate(`/dashboard`)
					}
					setIsVerifying(false)
				})
				.catch(error => {
					setIsVerifying(false)
					console.error(error)
					toast.error("Failed to verify email")
				})
		}
	}, [token, verify, navigate])

	return (
		<div className="py-32 w-full lg:grid">
			<div className="flex justify-center items-center py-12">
				<div className="mx-auto grid w-[350px] gap-6">
					{isVerifying ? (
						<div className="grid gap-2 text-center">
							<div className="mx-auto w-12 h-12 rounded-full border-t-2 border-b-2 border-gray-900 animate-spin"></div>
							<h3 className="h3">Verifying Your Email</h3>
							<p className="text-balance text-muted-foreground">
								Please wait while we verify your credentials...
							</p>
						</div>
					) : (
						<div className="grid gap-2 text-center">
							<MailCheck className="justify-self-center" />
							<h3 className="h3">Check your Email</h3>
							<p className="text-balance text-muted-foreground">
								We&apos;ve sent you a link to sign-in to your account. Be sure
								to check your spam.
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default VerifyEmail
