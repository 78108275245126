import { useEffect, useRef } from "react"
import {
	AnimatePresence,
	cubicBezier,
	motion,
	useAnimation,
	useInView,
} from "framer-motion"

const cardImage = [
	{
		id: 1,
		title: "Tom",
		link: "#",
		image: "https://avatar.iran.liara.run/public/2",
	},
	{
		id: 2,
		title: "Chris",
		link: "#",
		image: "https://avatar.iran.liara.run/public/48",
	},
	{
		id: 3,
		title: "PJ",
		link: "#",
		image: "https://avatar.iran.liara.run/public/13",
	},
	{
		id: 4,
		title: "Scott",
		link: "#",
		image: "https://avatar.iran.liara.run/public/4",
	},
	{
		id: 5,
		title: "Yehuda",
		link: "#",
		image: "https://avatar.iran.liara.run/public/75",
	},
	{
		id: 6,
		title: "Ezra",
		link: "#",
		image: "https://avatar.iran.liara.run/public/91",
	},
	{
		id: 7,
		title: "Ivey",
		link: "#",
		image: "https://avatar.iran.liara.run/public/66",
	},
	{
		id: 8,
		title: "Cat",
		link: "#",
		image: "https://avatar.iran.liara.run/public/88",
	},
	{
		id: 9,
		title: "Van",
		link: "#",
		image: "https://avatar.iran.liara.run/public/48",
	},
]

export const Hiring = () => {
	const containerRef = useRef(null)
	const inView = useInView(containerRef, { amount: 0.25 })
	const controls = useAnimation()

	useEffect(() => {
		if (inView) {
			controls.start("visible")
		} else {
			controls.start("hidden")
		}
	}, [inView, controls])

	return (
		<div className="flex flex-col gap-5 justify-between items-center w-full h-full bg-white rounded-3xl transform-gpu xl:flex-row">
			<div className="flex flex-col gap-4 justify-between items-start p-4 w-full md:p-8 xl:h-full xl:w-1/2">
				<p className="H3 text-balance text-stone-root">
					Completely removing the headache of hiring and training chatters.
				</p>
				<p className="text-stone-light">
					Freeing up a immense amount of time to focus on growing your business.
				</p>
			</div>
			<div className="overflow-hidden relative w-full rounded-2xl xl:w-1/2">
				<div className="absolute inset-x-0 top-0 z-10 h-1/3 bg-gradient-to-b from-white pointer-events-none dark:from-black"></div>
				<div
					ref={containerRef}
					className="relative -right-[50px] -top-[100px] grid max-h-[450px] grid-cols-3 gap-5 [transform:rotate(-15deg)translateZ(10px);]"
				>
					<AnimatePresence>
						{cardImage.map((card, index) => (
							<motion.div
								variants={{
									hidden: { opacity: 0, scale: 0.96, y: 25 },
									visible: { opacity: 1, scale: 1, y: 0 },
								}}
								initial="hidden"
								animate={controls}
								exit={{
									opacity: 0,
									scale: 0,
									transition: {
										duration: 0.1,
										ease: cubicBezier(0.22, 1, 0.36, 1),
									},
								}}
								transition={{
									duration: 0.2,
									ease: cubicBezier(0.22, 1, 0.36, 1),
									delay: index * 0.04,
								}}
								key={card.id}
								className="flex flex-col gap-y-2 items-center p-5 rounded-2xl border bg-white/5"
							>
								<img
									src={card.image}
									alt={card.title}
									className="object-cover w-20 h-20 rounded-full"
								/>
								<p className="detail dark:text-stone-200/50">{card.title}</p>
							</motion.div>
						))}
					</AnimatePresence>
				</div>
			</div>
		</div>
	)
}
