import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { Input } from "src/components/ui/Input"
import { Separator } from "src/components/ui/Separator"
import { SubmitButton } from "src/components/ui/SubmitButton"
import { useAuth } from "src/providers/AuthProvider"

const Register = () => {
	const [formData, setFormData] = useState({ fullName: "", email: "" })
	const [errors, setErrors] = useState({ fullName: "", email: "" })
	const [is_loading, setIsLoading] = useState(false)
	const navigate = useNavigate()
	const { user, register } = useAuth()

	useEffect(() => {
		if (user) {
			navigate("/dashboard")
		}
	}, [user, navigate])

	const validateField = (name: string, value: string) => {
		let error = ""
		if (name === "fullName") {
			if (value.trim().length < 2) {
				error = "Please enter a valid name"
			}
		} else if (name === "email") {
			const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			if (!re.test(value)) {
				error = "Please enter a valid email address"
			}
		}
		setErrors(prev => ({ ...prev, [name]: error }))
		return error === ""
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setFormData(prev => ({ ...prev, [name]: value }))
		validateField(name, value)
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFullNameValid = validateField("fullName", formData.fullName)
		const isEmailValid = validateField("email", formData.email)
		if (!isFullNameValid || !isEmailValid) return

		setIsLoading(true)
		try {
			await register(formData.email, formData.fullName)
			toast.success(
				"Registration successful! Check your email for verification."
			)
		} catch (error) {
			console.error("Sign up error:", error)
			if (error instanceof Error) {
				toast.error(
					error.message ||
						"An error occurred during registration. Please try again."
				)
			} else {
				toast.error("An unexpected error occurred. Please try again.")
			}
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="py-32 w-full lg:grid">
			<div className="flex justify-center items-center py-12">
				<div className="mx-auto grid w-[350px] gap-6">
					<div className="grid gap-2 text-center">
						<h1 className="H1">Sign up</h1>
						<p className="text-balance text-muted-foreground">
							Enter your details below to create an account.
						</p>
					</div>
					<form className="flex flex-col gap-2" onSubmit={handleSubmit}>
						<div className="flex flex-col gap-1">
							<Input
								name="fullName"
								id="fullName"
								type="text"
								placeholder="Enter your full name"
								required
								value={formData.fullName}
								onChange={handleChange}
								className={errors.fullName ? "border-red-root" : ""}
							/>
							{errors.fullName && (
								<span className="text-sm text-red-root">{errors.fullName}</span>
							)}
						</div>
						<div className="flex flex-col gap-1">
							<Input
								name="email"
								id="email"
								type="email"
								placeholder="Enter your email"
								required
								value={formData.email}
								onChange={handleChange}
								className={errors.email ? "border-red-root" : ""}
							/>
							{errors.email && (
								<span className="text-sm text-red-root">{errors.email}</span>
							)}
						</div>
						<SubmitButton
							is_loading={is_loading}
							type="submit"
							disabled={!!errors.fullName || !!errors.email}
						>
							Create Account
						</SubmitButton>
					</form>
					<Separator />
					<p className="text-sm text-center text-muted-foreground">
						Already have an account?{" "}
						<Link to="/login">
							<span className="font-medium underline">Login</span>
						</Link>
					</p>
				</div>
			</div>
		</div>
	)
}

export default Register
